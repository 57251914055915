import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Forms/Button'
import MuiDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const styles = {
  dialog: {
    padding: 20,
  },
}

const Dialog = (props) => (
  <MuiDialog
    style={styles.dialog}
    open={props.isDialogOpen}
    fullScreen={props.fullScreen}
    fullWidth={true}
    maxWidth={'md'}
  >
    {
      props.title ?
        <DialogTitle>{props.title}</DialogTitle>
        :
        null
    }
    <DialogContent>
      {props.children}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={props.handleClose}
        variant={'text'}
      >
        Zamknij
      </Button>
    </DialogActions>
  </MuiDialog>
)

Dialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  fullScreen: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default withMobileDialog()(Dialog)