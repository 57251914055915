import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Forms/Button'
import Dialog from '../Dialog'

class DialogWithButton extends React.Component {
  state = {
    isDialogOpen: false,
  }

  openDialog = () => this.setState({ isDialogOpen: true })
  closeDialog = () => this.setState({ isDialogOpen: false })

  render() {
    const addOpenDialogProp = (
      this.props.openDialogProp ?
        {
          [this.props.openDialogProp]: this.openDialog,
        }
        :
        {}
    )
    const addCloseDialogProp = (
      this.props.closeDialogProp ?
        {
          [this.props.closeDialogProp]: this.closeDialog,
        }
        :
        {}
    )
    const newChildrenProps = {
      ...addOpenDialogProp,
      ...addCloseDialogProp,
    }

    const childrenWithProps = React.Children.map(
      this.props.children,
      child => React.cloneElement(child, newChildrenProps)
    )

    return (
      <div>
        <Button
          fullWidth={true}
          onClick={this.openDialog}

          {...this.props.buttonProps}
        >
          {this.props.label}
        </Button>
        <Dialog
          title={this.props.title}
          isDialogOpen={this.state.isDialogOpen}
          handleClose={this.closeDialog}

          {...this.props.dialogProps}
        >
          {childrenWithProps}
        </Dialog>
      </div>
    )
  }
}

DialogWithButton.propTypes = {
  buttonProps: PropTypes.object,
  dialogProps: PropTypes.object,
  title: PropTypes.string,
  openDialogProp: PropTypes.string,
  closeDialogProp: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
}

export default DialogWithButton