
import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import checkPrivileges from '../../utils/checkPrivileges'
import RouteForbidden from '../RouteForbidden/RouteForbidden'

const PrivilegeChecker = (props) => {
  let privilegesAreValid = false

  try {
    checkPrivileges(props.privilegesToCheck, props._userPrivileges)
    privilegesAreValid = true
  } catch (error) {
    console.log(error) // eslint-disable-line no-console
    privilegesAreValid = false
  }

  return (
    privilegesAreValid ?
      <div>
        {props.children}
      </div>
      :
      <RouteForbidden />
  )
}

PrivilegeChecker.propTypes = {
  privilegesToCheck: PropTypes.object,
  children: PropTypes.node.isRequired,
  _userPrivileges: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  _userPrivileges: state.auth.userData && state.auth.userData.privileges,
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivilegeChecker)