import { enqueueSnackbar } from '../../state/ui'
import { push } from 'connected-react-router'

const defaultSnackBar = msg => enqueueSnackbar(msg, { variant: 'default' })
const errorSnackBar = msg => enqueueSnackbar(msg, { variant: 'error' })
const successSnackBar = msg => enqueueSnackbar(msg, { variant: 'success' })
const warningSnackBar = msg => enqueueSnackbar(msg, { variant: 'warning' })

const defaultError = [
  err => {
    console.error(err) // eslint-disable-line no-console

    return errorSnackBar('Coś poszło nie tak!')
  },
]



const defaultStateSingletonActions = {
  find: {
    error: defaultError,
    finished: [
      () => defaultSnackBar('Zakończono pobieranie'),
    ],
  },
  get: {
    error: defaultError,
    finished: () => defaultSnackBar('Pobrano rekord'),
  },
  create: {
    error: defaultError,
    finished: [
      () => successSnackBar('Zapisano rekord'),
      // @HACK push again same route to load data again
      // to display saved data
      () => push(),
    ],
  },
  patch: {
    error: defaultError,
    finished: [
      () => warningSnackBar('Zedytowano rekord'),
    ],
  },
  update: {
    error: defaultError,
    finished: [
      () => warningSnackBar('Nadpisano rekord'),
    ],
  },
  remove: {
    error: defaultError,
    finished: [
      () => errorSnackBar('Usunięto rekord'),
    ],
  },
}

export default defaultStateSingletonActions