export default (breakPoints, props) => breakPoints.reduce(
  (r, breakPoint, i, arr) => { // eslint-disable-line max-params
    if (props[breakPoint]) {
      return {
        ...r,
        [breakPoint]: props[breakPoint],
      }
    } else {
      return {
        ...r,
        [breakPoint]: r[arr[i - 1]],
      }
    }
  },
  {}
)