import React from 'react'
import PropTypes from 'prop-types'

import FieldsWithGrid from './FieldsWithGrid'
import Fields from './Fields'

const InnerGenerator = props => {
  const {
    fieldsArray,
    handleSubmit,
    grid,
    getFormikProps,
    ...custom
  } = props

  const FieldsComponent = (
    grid ?
      FieldsWithGrid
      :
      Fields
  )

  getFormikProps &&
  getFormikProps(props)

  return (
    <form
      onSubmit={handleSubmit}
    >
      <FieldsComponent
        fieldsArray={fieldsArray}
        grid={grid}
        {...custom}
      />
    </form>
  )
}

InnerGenerator.propTypes = {
  fieldsArray: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getFormikProps: PropTypes.func,
  grid: PropTypes.object,
}

export default InnerGenerator