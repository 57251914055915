export default {
  pagination: {
    labelDisplayedRows: '{from}-{to} z {count}', // {from}-{to} of {count}
    labelRowsPerPage: 'Wierszy na stronę:', // Rows per page:
    firstAriaLabel: 'Pierwsza strona', // First Page
    firstTooltip: 'Pierwsza strona', // First Page
    previousAriaLabel: 'Poprzednia strona', // Previous Page
    previousTooltip: 'Poprzednia strona', // Previous Page
    nextAriaLabel: 'Następna strona', // Next Page
    nextTooltip: 'Następna strona', // Next Page
    lastAriaLabel: 'Ostatnia strona', // Last Page
    lastTooltip: 'Ostatnia strona', // Last Page
  },
  toolbar: {
    nRowsSelected: '{0} wiersz(y) wybranych', // {0} row(s) selected
    showColumnsTitle: 'Pokaż kolumny', // Show Columns
    showColumnsAriaLabel: 'Pokaż kolumny', // Show Columns
    exportTitle: 'Eksportuj', // Export
    exportAriaLabel: 'Eksportuj', // Export
    exportName: 'Eksportuj CSV', // Export as CSV
    searchTooltip: 'Szukaj', // Search
  },
  header: {
    actions: 'Akcje', // Actions
  },
  body: {
    // No records to display
    emptyDataSourceMessage: 'Brak rekordów do wyświetlenia!', 
    filterRow: {
      filterTooltip: 'Filtruj', // Filter
    },
  },
}