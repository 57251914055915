import React from 'react'
import PropTypes from 'prop-types'

import LoadingIndicator from '../../LoadingIndicator'

const Loader = (props) => (
  props.serviceData.loading ?
    <LoadingIndicator
      message={'Ładowanie...'}
    />
    :
    props.serviceData.isSaving ?
      <LoadingIndicator
        message={'Zapisywanie...'}
      />
      :
      null
)

Loader.propTypes = {
  serviceData: PropTypes.object.isRequired,
}

export default Loader