import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { connect } from 'react-redux'
import {
  closeConfirmDialogAction,
  acceptConfirmDialogAsyncAction,
} from '../../state/ui'

const AlertDialog = props => (
  <Dialog
    open={props._isConfirmDialogOpen}
    onClose={props._closeConfirmDialogAction}
  >
    <DialogTitle>
      {props._confirmDialogTitle}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {props._confirmDialogText}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={props._closeConfirmDialogAction}
        color="primary"
      >
        Nie
      </Button>
      <Button
        onClick={props._acceptConfirmDialogAsyncAction}
        color="primary"
        autoFocus
      >
        Tak
      </Button>
    </DialogActions>
  </Dialog>
)

AlertDialog.propTypes = {
  _isConfirmDialogOpen: PropTypes.bool.isRequired,
  _closeConfirmDialogAction: PropTypes.func.isRequired,
  _acceptConfirmDialogAsyncAction: PropTypes.func.isRequired,
  _confirmDialogTitle: PropTypes.string,
  _confirmDialogText: PropTypes.string,
}

const mapStateToProps = state => ({
  _isConfirmDialogOpen: state.ui.isConfirmDialogOpen,
  _confirmDialogTitle: state.ui.confirmDialogTitle,
  _confirmDialogText: state.ui.confirmDialogText,
})

const mapDispatchToProps = dispatch => ({
  _closeConfirmDialogAction: () => dispatch(closeConfirmDialogAction()),
  _acceptConfirmDialogAsyncAction: () => (
    dispatch(acceptConfirmDialogAsyncAction())
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDialog)