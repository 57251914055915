import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import BackgroundFieldWrapper from '../../BackgroundFieldWrapper'

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: 4,
  },
})

const Input = (props) => {
  const {
    name,
    values,
    handleChange,
    isFullScreen,
    editorComponent,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    label,
    labelShrink,
    errors,
    handleBlur,
    touched,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  const value = typeof values[name] === 'string' ? values[name] : ''

  const Editor = editorComponent

  return (
    <BackgroundFieldWrapper style={{
      marginTop: 0,
      display: 'flex',
    }
    }>
      <Editor 
        value={value}
        name={name}
        handleChange={handleChange}
        isFullScreen={isFullScreen}
        {...custom}
      />
    </BackgroundFieldWrapper>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  editorComponent: PropTypes.node.isRequired,
}


export default withStyles(styles)(Input)