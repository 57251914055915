import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import IconLoader from '../../../components/IconLoader'

const DrawerItem = (props) => {
  const Icon = iconProps => {
    const Component = props.route.icon

    return (
      <Suspense fallback={<IconLoader />}>
        <Component {...iconProps} />
      </Suspense>
    )
  }

  const onClickHandler = () => {
    props.goToRoute && props.goToRoute(
      Array.isArray(props.route.path) ? props.route.path[0] : props.route.path
    )
    props.handleGroupToggle && props.handleGroupToggle()
    props.handleDrawerToggle && props.handleDrawerToggle()
  }

  const shouldBeVisible = (
    props.checkIfItIsOnPath(props.route)
  )

  return (
    <div onClick={onClickHandler}>
      {
        props.route.sideBar.separatorAbove === true ?
          <Divider />
          :
          null
      }
      <ListItem
        className={props.className}
        button={true}
        selected={shouldBeVisible}
      >
        {
          props.route.icon ?
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            :

            null
        }
        <ListItemText primary={props.route.name} />
        {
          props.isGroupOpen !== undefined ?
            props.isGroupOpen ?
              <ExpandLess />
              :
              <ExpandMore />
            :
            null
        }
      </ListItem>
      {props.route.sideBar.separatorBelow === true ? <Divider /> : null}
    </div>
  )
}

DrawerItem.propTypes = {
  className: PropTypes.string,
  goToRoute: PropTypes.func,
  handleGroupToggle: PropTypes.func,
  isGroupOpen: PropTypes.bool,
  route: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func,
  checkIfItIsOnPath: PropTypes.func.isRequired,
}

export default DrawerItem