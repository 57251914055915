import React from 'react'
import PropTypes from 'prop-types'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import toolbar from './toolbar'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

const EditorDraft = (props) => {
  const {
    label,
    name,
    values,
    errors,
    touched,
    handleChange,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <div>
      <h2>{label}</h2>
      <Editor
        toolbar={toolbar}
        initialContentState={values[name]}
        onContentStateChange={
          state => handleChange(createPseudoEvent(state, name))
        }
        localization={{
          locale: 'pl',
        }}
        editorStyle={{
          borderRadius: 2,
          border: '1px solid #F1F1F1',
          padding: '0 15px',
        }}
        wrapperStyle={{
          marginBottom: 20,
        }}
        errors={errors[name]}
        touched={touched[name]}
        {...custom}
      />
    </div>
  )
}

EditorDraft.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

export default EditorDraft