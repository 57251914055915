import React from 'react'
import PropTypes from 'prop-types'

import Select from '../../../Select'

const SelectTaxonomy = (props) => (
  <div>
    <Select
      name={props.taxonomy.name}
      values={{ [props.taxonomy.name]: (props.value && props.value[0]) || '' }}
      options={props.taxonomy.predefinedValues}
      handleChange={
        event => props.onChange(
          props.taxonomy.id,
          [event.target.value]
        )
      }
      formControlProps={{
        fullWidth: true,
      }}
      label={'Wybierz z listy'}
    />
  </div>
)

SelectTaxonomy.propTypes = {
  onChange: PropTypes.func.isRequired,
  taxonomy: PropTypes.object.isRequired,
  value: PropTypes.array,
}

export default SelectTaxonomy