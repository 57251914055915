export default (gridArr, componentsArray) => (
  gridArr.map(
    row => (
      row.map(
        cell => (
          cell &&
          componentsArray.find(
            component => component.name === cell
          ).element
        )
      )
    )
  )
)