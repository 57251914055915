/* eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'

import Cropper from '../../../components/Cropper'
import Button from '../../../components/Forms/Button'
import FileButton from '../FileButton/FileButton'

const UpLoaderInner = props => {
  const {
    upload,
    file,
    completed,
    handleFileChange,
    onSuccess,
    onFailure,
    fileSrc,
    getCroppedCanvasFunc,
    // can be cropper.js props
    ...rest
  } = props

  return (
    <div>
      <h3>Postęp przesyłania</h3>
      <h4>{file && file.name}</h4>
      <LinearProgress
        variant={'determinate'}
        value={completed}
      />
      <br />
      <FileButton
        accept={'image/*'}
        onChange={handleFileChange}
      >
        Wybierz zdjęcie
      </FileButton>
      <Button
        onClick={
          () => (
            upload()
              .then(data => {
                onSuccess && onSuccess(data)
              })
              .catch(err => onFailure && onFailure(err))
          )
        }
        fullWidth={true}
        color={'secondary'}
      >
        Wyślij na serwer
      </Button>
      {
        fileSrc ?
          <div>
            <h3>Przytnij zdjecie</h3>
            <Cropper
              {...rest}
              src={fileSrc}
              getCroppedCanvasFunc={getCroppedCanvasFunc}
            />
          </div>
          :
          null
      }
    </div >
  )
}

UpLoaderInner.propTypes = {
  file: PropTypes.object,
  fileSrc: PropTypes.string,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  upload: PropTypes.func.isRequired,
  completed: PropTypes.number.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  getCroppedCanvasFunc: PropTypes.func.isRequired,
}

export default UpLoaderInner