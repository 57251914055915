import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiTextField from '@material-ui/core/TextField'

import slugify from 'slugify'

import './Slug.css'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
})

const Slug = (props) => {
  const {
    name,
    label,
    labelShrink,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  const slugCurrentValue = values[name]
  const slugFromFieldValue = values[custom.from]
  const value = slugify(
    typeof slugFromFieldValue === 'string' ? slugFromFieldValue : ''
  ).toLowerCase()

  if(slugCurrentValue !== value){
    handleChange(createPseudoEvent(value, name))
  }

  return (
    <MuiTextField
      className={`Slug-${name}`}
      name={name}
      label={label || name}
      value={value}
      helperText={(touched[name] && errors[name]) ? errors[name] : ''}
      onBlur={handleBlur}
      error={(touched[name] && errors[name]) ? true : false}
      disabled={true}
      InputLabelProps={{
        shrink: labelShrink,
        readOnly: true,
      }}
      {...custom}
    />
  )
}

Slug.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelShrink: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
}

export default withStyles(styles)(Slug)