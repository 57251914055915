import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const styles = theme => ({
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  muiAppBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
})

const AppBar = (props) => {
  const { classes } = props

  return (
    <div>
      <MuiAppBar
        className={classes.muiAppBar}
      >
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={props.handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography
            variant="title"
            color="inherit"
            noWrap={true}
            className={classes.flex}
          >
            AmazingCMS - Panel Administratora
          </Typography>
          <Button
            color="inherit"
            onClick={props.handleLogOut}
          >
            Wyloguj
          </Button>
        </Toolbar>
      </MuiAppBar>
    </div>
  )
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true })(AppBar)