import React from 'react'
import PropTypes from 'prop-types'

import Select from '../../../Select'

const SelectMultipleTaxonomy = (props) => (
  <div>
    <Select
      name={props.taxonomy.name}
      values={{ [props.taxonomy.name]: props.value || [] }}
      options={props.taxonomy.predefinedValues}
      multiple={true}
      handleChange={
        event => props.onChange(
          props.taxonomy.id,
          event.target.value
        )
      }
      formControlProps={{
        fullWidth: true,
      }}
      label={'Wybierz z listy (wiele wartości)'}
    />
  </div>
)

SelectMultipleTaxonomy.propTypes = {
  onChange: PropTypes.func.isRequired,
  taxonomy: PropTypes.object.isRequired,
  value: PropTypes.array,
}

export default SelectMultipleTaxonomy