import React from 'react'
import PropTypes from 'prop-types'

import Img from 'react-image'
import CircularProgress from '@material-ui/core/CircularProgress'

import getFileSrc from '../../../utils/getFileSrc'

const ImgPreview = (props) => (
  <div
    style={{
      height: 200,
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
    }}
  >
    <Img
      style={{
        borderRadius: 4,
        maxHeight: 200,
        maxWidth: '100%',
      }}
      src={getFileSrc(props.photo._id, { resize: { height: 200 } })}
      alt={'lead '}
      loader={
        <CircularProgress
          style={{
            padding: 40,
          }}
        />
      }
    />
  </div>
)

ImgPreview.propTypes = {
  photo: PropTypes.object.isRequired,
}

export default ImgPreview