import React from 'react'
import PropTypes from 'prop-types'

import MaterialTable, { MTableActions } from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'

import plLocale from './pl-locale'

const Table = (props) => {
  const {
    options,
    localization,
    total,
    ...rest
  } = props

  const CustomPagination = props => (
    total ?
      <TablePagination
        {...props}
        count={total}
      />
      :
      <TablePagination
        {...props}
      />
  )

  const CustomActions = props => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <MTableActions
        {...props}
      />
    </div>
  )

  return (
    <div>
      <MaterialTable
        components={{
          Pagination: CustomPagination,
          Actions: CustomActions,
        }}
        options={{
          actionsColumnIndex: -1,
          columnsButton: true,
          exportButton: false,
          search: false,
          pageSizeOptions: [10, 25, 50],
          pageSize: 10,
          ...options,
        }}
        localization={{
          ...plLocale,
          ...localization,
        }}
        {...rest}
      />
    </div>
  )
}

Table.defaultProps = {
  title: '',
}

Table.propTypes = {
  options: PropTypes.object,
  localization: PropTypes.object,
  total: PropTypes.number,
}

export default Table