import React from 'react'

import NotListedLocationIcon from '@material-ui/icons/NotListedLocation'

const styles = {
  main: {
    padding: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    fontSize: 100,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}

const RouteNotFound = (props) => (
  <div
    style={styles.main}
  >
    <NotListedLocationIcon
      color={'disabled'}
      style={styles.icon}
    />
    <h2
      style={styles.text}
    >
      Nie znaleziono takiej strony!
    </h2>
  </div>
)

export default RouteNotFound