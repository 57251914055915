import uuidv1 from 'uuid/v1'

import moveArrayElement from '../../../utils/moveArrayElement'

export const stateChangedCallback = function () {
  this.props.handleChange({
    target: {
      name: this.props.name,
      value: this.state.options,
    },
  })
}

export const handleValueChange = function (event) {
  this.setState({
    errorMessage: null,
    value: event.target.value,
  })
}

export const displayError = function (message) {
  this.setState({ errorMessage: message })
}

export const onAddClick = function () {
  if (this.props.disallowDuplicates) {
    const duplicates = this.state.options.filter(
      option => option.label === this.state.value
    )

    if (duplicates.length > 0) {
      displayError.call(this, 'Taka pozycja jest już na liście!')
      return
    }
  }

  this.setState({
    value: '',
    options: this.state.options.concat({
      value: uuidv1(),
      label: this.state.value,
    }),
  }, this.stateChangedCallback)
}

export const onDeleteClick = function (id) {
  this.setState({
    options: this.state.options.filter(option => option.value !== id),
  }, this.stateChangedCallback)
}

export const handleEdit = function (id, event) {
  this.setState({
    options: this.state.options.map(
      option => (
        option.value === id ?
          {
            ...option,
            label: event.target.value,
          }
          :
          option
      )
    ),
  }, this.stateChangedCallback)
}

export const moveUp = function (index) {
  this.setState({
    options: moveArrayElement(
      this.state.options,
      index,
      -1
    ),
  }, this.stateChangedCallback)
}

export const moveDown = function (index) {
  this.setState({
    options: moveArrayElement(
      this.state.options,
      index,
      1
    ),
  }, this.stateChangedCallback)
}