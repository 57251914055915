export default [
  {
    fieldType: 'text-field',
    name: 'email',
    label: 'E-mail',
    validate: {
      requiredCheck: true,
      emailCheck: true,
    },
    props: {
      type: 'email',
      fullWidth: true,
      labelShrink: true,
    },
    initialValue: '',
  },
  {
    fieldType: 'text-field',
    name: 'password',
    label: 'Hasło',
    validate: {
      requiredCheck: true,
    },
    props: {
      type: 'password',
      fullWidth: true,
      labelShrink: true,
    },
    initialValue: '',
  },
  {
    fieldType: 'button',
    name: 'button-submit',
    label: 'Zaloguj',
    props: {
      type: 'submit',
      fullWidth: true,
    },
  },
]