/* eslint-disable max-lines */
import jwt from 'jsonwebtoken'

import { logIn, logOut, checkIfLoggedIn, sendForgotPasswordEmail } from '../db'
import { errorSnackBar, successSnackBar, defaultSnackBar } from './ui'

const SET_USER_DATA = 'auth/SET_USER_DATA'
const SET_USER_IS_LOGGED_IN = 'auth/SET_USER_IS_LOGGED_IN'
const SET_USER_IS_LOGGED_OUT = 'auth/SET_USER_IS_LOGGED_OUT'

const USER_IS_LOGGING_IN = 'auth/USER_IS_LOGGING_IN'
const USER_LOGIN_FIALURE = 'auth/USER_LOGIN_FIALURE'

export const sendForgotPasswordEmailAsyncAction = email => dispatch => {
  dispatch(defaultSnackBar('Wysłamy do Ciebie maila!'))
  sendForgotPasswordEmail(email)
    .then(() => dispatch(successSnackBar('Wysłano e-mail z instrukcjami!')))
    .catch(err => {
      console.log(err, err.response.data) // eslint-disable-line no-console

      if (err.response.data.message === 'No such user!') {
        dispatch(errorSnackBar(
          'Nie istnieje użytkownik o podanym adresie e-mail!'
        ))
      } else {
        dispatch(errorSnackBar('Coś poszło nie tak!'))
      }
    })
}

const loginSuccessful = dispatch => userData => {
  const { accessToken, refreshToken } = userData
  dispatch(successSnackBar('Poprawnie zalogowano!'))
  dispatch(setUserDataAction(jwt.decode(accessToken)))
  dispatch(setUserIsLoggedInAction(accessToken, refreshToken))
  return userData
}
const handleErrorNotification = (dispatch, error) => {
  const message = (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) || error.message

  const displayedErrors = {
    'Invalid password': 'Niepoprawne hasło!',
    'User not found!': 'Nie znaleziono użytkownika!',
  }
  const notDisplayedErrors = [
    'No token in storage!',
  ]
  if (Object.keys(displayedErrors).includes(message)) {
    dispatch(errorSnackBar('Nieudane logowanie - ' + displayedErrors[message]))
  } else if (!notDisplayedErrors.includes(message)) {
    dispatch(errorSnackBar('Wystąpił problem związany z autoryzacją!'))
  }
}
const loginFailed = dispatch => error => {
  handleErrorNotification(dispatch, error)
  dispatch(userLoginFailureAction(error))
  dispatch(setUserIsLoggedOutAction())
  return error
}

export const createLogInAsyncAction = logInFromDb => (
  (email, password) => (dispatch, getState) => {
    dispatch(defaultSnackBar('Logowanie...'))
    dispatch(userIsLoggingInAction())

    return logInFromDb(email, password)
      .then(loginSuccessful(dispatch))
      .catch(loginFailed(dispatch))
  }
)

export const logInAsyncAction = createLogInAsyncAction(logIn)

const createCheckIfLoggedInAsyncAction = checkIfLoggedInFromDb => (
  () => (dispatch, getState) => {
    dispatch(userIsLoggingInAction())

    return checkIfLoggedInFromDb()
      .then(loginSuccessful(dispatch))
      .catch(loginFailed(dispatch))
  }
)

export const checkIfLoggedInAsyncAction = (
  createCheckIfLoggedInAsyncAction(checkIfLoggedIn)
)

export const createLogOutAsyncAction = logOutFormDb => (
  () => (dispatch, getState) => {
    return logOutFormDb()
      .then(() => {
        dispatch(setUserIsLoggedOutAction())
      })
  }
)

export const logOutAsyncAction = createLogOutAsyncAction(logOut)

export const setUserDataAction = userData => ({
  type: SET_USER_DATA,
  userData,
})
export const setUserIsLoggedInAction = (accessToken, refreshToken) => ({
  type: SET_USER_IS_LOGGED_IN,
  accessToken,
  refreshToken,
})
export const setUserIsLoggedOutAction = () => ({ type: SET_USER_IS_LOGGED_OUT })

export const userIsLoggingInAction = () => ({ type: USER_IS_LOGGING_IN })
export const userLoginFailureAction = loginError => ({
  type: USER_LOGIN_FIALURE,
  loginError,
})

export const initialState = {
  isUserLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  userData: null,
  userIsLoggingIn: false,
  loginError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_IS_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: true,
        userIsLoggingIn: false,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        loginError: initialState.loginError,
      }
    case SET_USER_IS_LOGGED_OUT:
      return {
        ...initialState,
        loginError: state.loginError,
      }
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      }
    case USER_IS_LOGGING_IN:
      return {
        ...state,
        userIsLoggingIn: true,
      }
    case USER_LOGIN_FIALURE:
      return {
        ...state,
        loginError: action.loginError,
        userIsLoggingIn: false,
      }
    default:
      return state
  }
}