import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import DialogWithButton from '../../../components/DialogWithButton'
import UpLoaderInner from './UpLoaderInner'

import getFileSrc from '../../../utils/getFileSrc'

import {
  readImage,
  handleFileChange,
  upload,
  getCroppedCanvasFunc,
} from './UpLoader.logic'

class UpLoader extends React.Component {
  constructor(props) {
    super(props)

    this.readImage = readImage.bind(this)
    this.handleFileChange = handleFileChange.bind(this)
    this.upload = upload.bind(this)
    this.getCroppedCanvasFunc = getCroppedCanvasFunc.bind(this)

    this.state = {
      fileSrc: props.photo && props.photo._id && getFileSrc(props.photo._id),
      file: null,
      fileName: props.photo.filename,
      getCroppedCanvas: null,
      completed: 0,
    }
  }

  componentDidUpdate(prevProps) {
    const currentValue = this.props.photo
    const prevValue = prevProps.photo

    if (currentValue && (currentValue !== prevValue)) {
      this.setState({
        fileSrc: getFileSrc(currentValue._id),
        fileName: currentValue.filename || null,
      })
    }
  }

  render() {
    const {
      inDialog,
      label,
      // can be cropper.js props
      ...rest
    } = this.props

    const upLoaderInner = (
      <UpLoaderInner
        {...rest}
        completed={this.state.completed}
        fileSrc={this.state.fileSrc}
        file={this.state.file}
        handleFileChange={this.handleFileChange}
        upload={this.upload}
        getCroppedCanvasFunc={this.getCroppedCanvasFunc}
      />
    )

    return (
      inDialog ?
        <DialogWithButton
          label={label}
          title={label}
          closeDialogProp={'onSuccess'}
        >
          {upLoaderInner}
        </DialogWithButton>
        :
        upLoaderInner
    )
  }
}

UpLoader.defaultProps = {
  inDialog: true,
}

UpLoader.propTypes = {
  photo: PropTypes.object,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  label: PropTypes.string,
  inDialog: PropTypes.bool.isRequired,
}

export default connect(
  null,
  dispatch => ({
    _dispatch: (...all) => dispatch(...all),
  })
)(UpLoader)