import Button from '../Button'
import TextField from '../TextField'
import Select from '../Select'
import EditorDraft from '../EditorDraft'
import UpLoader from '../UpLoader'
import Switch from '../Switch'
import Radio from '../Radio'
import SelectCreator from '../SelectCreator'
import TaxonomiesPicker from '../TaxonomiesPicker'
import DateTimePicker from '../DateTimePicker'
import ArrayOfFields from '../ArrayOfFields'
import ColorPicker from '../ColorPicker'
import PhotosSelect from '../PhotosSelect'
import FilesSelect from '../FilesSelect'
import MDXEditor from '../MDXEditor'
import Slug from '../Slug'

export default {
  'mdx': MDXEditor,
  'text-field': TextField,
  'slug': Slug,
  'select': Select,
  'button': Button,
  'editor-draft': EditorDraft,
  'uploader': UpLoader,
  'switch': Switch,
  'radio': Radio,
  'select-creator': SelectCreator,
  'taxonomies-picker': TaxonomiesPicker,
  'datetime-picker': DateTimePicker,
  'array-of-fields': ArrayOfFields,
  'color-picker': ColorPicker,
  'photos-select': PhotosSelect,
  'files-select': FilesSelect,
}