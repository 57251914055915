import { yearFormat, dateFormat, dateTimeFormat } from '../../../options'

import {
  TimePicker,
  DatePicker,
  DateTimePicker,
} from 'material-ui-pickers'

export default {
  'time': { Component: TimePicker, format: 'HH:mm' },
  'year': { Component: DatePicker, format: yearFormat, views: ['year'] },
  'date': { Component: DatePicker, format: dateFormat },
  'datetime': { Component: DateTimePicker, format: dateTimeFormat },
}