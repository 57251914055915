export default (
  privilegesToCheck, usersPrivileges
) => {
  if (
    !privilegesToCheck ||
    privilegesToCheck.length === 0
  ) return

  const matchedPrivileges = privilegesToCheck.filter(
    privilegeToCheck => usersPrivileges.includes(privilegeToCheck)
  )

  if (matchedPrivileges.length === 0) {
    // eslint-disable-next-line max-len
    const message = `User does not have required privileges for that action! Missing privileges: ${privilegesToCheck.join(', ')}.`
    throw new Error(message)
  }
}