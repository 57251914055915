import reduxApi from 'redux-api'

import axios from '../axios'

const axiosAdapter = (url, options) => {
  const axiosOptions = {
    ...options,
    data: options.body,
  }
  return axios(url, axiosOptions).then(response => response.data)
}

const URL_CORE = window._env_.REACT_APP_URL_CORE
const URL_COLLECTIONS = window._env_.REACT_APP_URL_COLLECTIONS
const URL_PHOTOS_UPLOADER = window._env_.REACT_APP_URL_UPLOADER + '/photos'
const URL_FILES_UPLOADER = window._env_.REACT_APP_URL_UPLOADER + '/files'

export default reduxApi({
  users: {
    url: `${URL_CORE}/api/users/:id`,
    crud: true,
  },
  groups: {
    url: `${URL_COLLECTIONS}/api/actions/groups/:id`,
    crud: true,
  },
  collections: {
    url: `${URL_COLLECTIONS}/api/collections/:id`,
    crud: true,
  },
  actions: {
    url: `${URL_COLLECTIONS}/api/actions/:collectionName/:id`,
    crud: true,
  },
  photos: {
    url: `${URL_PHOTOS_UPLOADER}/:id`,
    crud: true,
  },
  files: {
    url: `${URL_FILES_UPLOADER}/:id`,
    crud: true,
  },
})
  .use('fetch', axiosAdapter)
  .use('options', (url, params, getState) => {
    const accessToken = localStorage.getItem('accessToken')

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    if (accessToken) {
      return { headers: { ...headers, Authorization: `Bearer ${accessToken}` } }
    }

    return { headers }
  })