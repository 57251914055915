import React from 'react'
import PropTypes from 'prop-types'

import PaperRefined from '../../components/PaperRefined'
import FormsGenerator from '../../components/Forms/Generator'
import Button from '../../components/Forms/Button'

import loginFieldsArray from './login.form'
import forgotPasswordFieldsArray from './forgot-pass.form'

const styles = {
  main: {
    width: '100%',
    paddingTop: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

class Forms extends React.Component {
  state = {
    forgotPasswordForm: false,
  }

  render() {
    return (
      <div
        style={styles.main}
      >
        <PaperRefined
          title={
            'AmazingCMS - Panel Administratora - Zaloguj się!'
          }
        >
          <FormsGenerator
            handleSubmit={(values, formikBag) => {
              const { email, password } = values
              this.props.onLogInHandler(email, password)
            }}
            fieldsArray={loginFieldsArray}
          />
          {/* <Button
            variant={'text'}
            fullWidth={true}
            onClick={
              () => this.setState({
                forgotPasswordForm: !this.state.forgotPasswordForm,
              })
            }
          >
            Zapomniałem hasła
          </Button>
          {
            this.state.forgotPasswordForm ?
              <FormsGenerator
                handleSubmit={(values, formikBag) => {
                  const { email, password } = values
                  this.props.onForgotPasswordHandler(email, password)
                }}
                fieldsArray={forgotPasswordFieldsArray}
              />
              :
              null
          } */}
        </PaperRefined>
      </div>
    )
  }
}

Forms.propTypes = {
  onForgotPasswordHandler: PropTypes.func.isRequired,
  onLogInHandler: PropTypes.func.isRequired,
}

export default Forms