import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { withFormik } from 'formik'

import InnerGenerator from './InnerGenerator'

// eslint-disable-next-line max-len
import mapFieldsObjToFormikValidationObj from './mapFieldsObjToFormikValidationObj'
import formikValidation from '../../../utils/formikValidation'

const Generator = props => {
  const {
    fieldsArray,
    handleSubmit,
    grid,
  } = props

  const values = fieldsArray.reduce(
    (r, fieldObj) => (
      fieldObj.fieldType === 'button' ?
        r
        :
        {
          ...r,
          [fieldObj.name]: (
            fieldObj.initialValue === undefined ?
              ''
              :
              fieldObj.initialValue
          ),
        }
    ),
    {}
  )

  const doNotSendFieldsNames = (
    fieldsArray
      .filter(field => field.doNotSend)
      .map(field => field.name)
  )

  const handleSubmitWithoutDoNotSendFields = (
    data => {
      const dataToSend = _.omit(data, doNotSendFieldsNames)

      return handleSubmit(dataToSend)
    }
  )

  const validationObj = mapFieldsObjToFormikValidationObj(fieldsArray)

  const InnerGeneratorWithProps = otherProps => (
    <InnerGenerator
      getFormikProps={props.getFormikProps}
      {...otherProps}
    />
  )

  const GeneratorWithFormik = withFormik({
    mapPropsToValues: () => values,
    handleSubmit: (
      props.testMode ?
        console.log // eslint-disable-line no-console
        :
        handleSubmitWithoutDoNotSendFields
    ),
    validate: (...all) => {
      props.onChange && props.onChange(...all)
      return formikValidation(validationObj)(...all)
    },
  })(InnerGeneratorWithProps)

  return (
    <GeneratorWithFormik
      fieldsArray={fieldsArray}
      grid={grid}
    />
  )
}

Generator.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fieldsArray: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  grid: PropTypes.object,
  testMode: PropTypes.bool,
  getFormikProps: PropTypes.func,
}

Generator.defaultProps = {
  testMode: false,
}

export default Generator