import React from 'react'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import mapTypesToComponents from './mapTypesToComponents'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

const DateTimePicker = (props) => {
  const {
    type,
    label,
    name,
    values,
    handleChange,
    formControlProps,
    formLabelProps,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  const { Component, format, views } = mapTypesToComponents[type]

  return (
    <FormControl
      component={'fieldset'}
      {...formControlProps}
    >
      <FormLabel
        component={'legend'}
        style={{
          transform: ' scale(0.75)',
          transformOrigin: 'top left',
        }}
        {...formLabelProps}
      >
        {label}
      </FormLabel>
      <Component
        value={values[name]}
        format={format}
        views={views}
        ampm={['time', 'datetime'].includes(type) ? false : undefined}
        cancelLabel={'Zamknij'}
        okLabel={'Wybierz'}
        onChange={
          value => handleChange(
            createPseudoEvent(value.toISOString(), name)
          )
        }
        {...custom}
      />
    </FormControl>
  )
}

DateTimePicker.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  formLabelProps: PropTypes.object,
  formControlProps: PropTypes.object,
}

DateTimePicker.defaultProps = {
  type: 'date',
}

export default DateTimePicker