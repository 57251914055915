import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'

import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import auth, { checkIfLoggedInAsyncAction } from './state/auth'
import ui from './state/ui'

import api from './db/rest/api'

export const history = createBrowserHistory({
  basename: window._env_.REACT_APP_ROUTER_BASENAME || '',
})

const rootReducer = combineReducers({
  auth,
  ui,
  ...api.reducers,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
      promiseMiddleware()
    )
  )
)

store.dispatch(checkIfLoggedInAsyncAction())