import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiTextField from '@material-ui/core/TextField'

import './TextField.css'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
})

const TextField = (props) => {
  const {
    name,
    label,
    labelShrink,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <MuiTextField
      className={`TextField-${name}`}
      name={name}
      label={label || name}
      value={values[name]}
      helperText={(touched[name] && errors[name]) ? errors[name] : ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={(touched[name] && errors[name]) ? true : false}
      InputLabelProps={{
        shrink: labelShrink,
      }}
      {...custom}
    />
  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelShrink: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
}

export default withStyles(styles)(TextField)