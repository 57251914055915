import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import MainRouter from './router/MainRouter'
import Navigation from './containers/Navigation'
import ConfirmDialog from './containers/ConfirmDialog'

import makeRoutes from './makeRoutes'

import withServiceLoaded from './components/ViewsGenerator/withServiceLoaded'

const AppInner = (props) => {
  const routes = makeRoutes({
    collectionsData: (
      props._serviceData &&
      props._serviceData.data &&
      props._serviceData.data.rows
    ),
    userData: props._userData,
  })

  return (
    <div>
      <Navigation routes={routes}>
        <MainRouter routes={routes} />
      </Navigation>
      <ConfirmDialog />
    </div>
  )
}

AppInner.propTypes = {
  _serviceData: PropTypes.object.isRequired,
  _userData: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  _userData: state.auth.userData,
})

export default connect(
  mapStateToProps
)(withServiceLoaded('collections')(AppInner))
