import { warningSnackBar } from '../../../state/ui'

import base64ToBlob from '../../../utils/base64ToBlob'
import { uploadFile as uploadImage } from '../../../db/upload/file'

import getFileSrc from '../../../utils/getFileSrc'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

export const readImage = function (file) {
  const reader = new FileReader()

  reader.readAsDataURL(file)

  reader.onload = event => {
    this.setState({
      fileSrc: event.target.result,
    })
  }
}

export const handleFileChange = function (e) {
  const file = e.target.files[0]

  if (!file) return

  if (file.size > 10 * 1024 * 1024) {
    this.props._dispatch(
      warningSnackBar(
        'Plik jest za duży! Maksymalna wielkość pliku to 10 MB!'
      )
    )
    return
  }

  this.setState({
    file,
    fileName: file.name,
  })

  this.readImage(file)
}

export const upload = function () {
  const croppedSrc = (
    this.state.getCroppedCanvas &&
    this.state.getCroppedCanvas(
      this.state &&
      this.state.file &&
      this.state.file.type
    )
  )

  if (!croppedSrc) return

  const formData = new FormData()
  const fileAsBlob = base64ToBlob(croppedSrc)

  formData.append('file', fileAsBlob, this.state.fileName)

  return uploadImage(
    formData,
    {
      onUploadProgress: (p) => (
        this.setState({ completed: (p.loaded / p.total) * 100 })
      ),
    }
  )
    .then(data => {
      this.setState({
        fileSrc: getFileSrc(data._id),
        completed: 0,
      })

      this.props.handleChange &&
        this.props.handleChange(createPseudoEvent(data, this.props.name))

      this.props.onSuccess && this.props.onSuccess(data)
    })
    .catch(err => this.props.onFailure && this.props.onFailure(err))
}

export const getCroppedCanvasFunc = function (getCroppedCanvas) {
  this.setState({ getCroppedCanvas })
}
