import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import MuiDrawer from '@material-ui/core/Drawer'

const drawerWidth = 240

const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerWrapper: {
    [theme.breakpoints.up('md')]: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      width: drawerWidth,
      minWidth: drawerWidth,
      flexBasis: drawerWidth,
      paddingRight: 15,
    },
  },
})

const Drawer = (props) => (
  <div
    className={props.classes.drawerWrapper}
  >
    <Hidden mdUp>
      <MuiDrawer
        variant="temporary"
        anchor={props.theme.direction === 'rtl' ? 'right' : 'left'}
        open={props.isSideBarOpen}
        onClose={props.handleDrawerToggle}
        classes={{
          paper: props.classes.drawerPaper,
        }}
        // ModalProps - Better open performance on mobile
        ModalProps={{
          keepMounted: true,
        }}
      >
        {props.children}
      </MuiDrawer>
    </Hidden>
    <Hidden smDown implementation="css">
      <MuiDrawer
        variant="permanent"
        open
        classes={{
          paper: props.classes.drawerPaper,
        }}
      >
        <div className={props.classes.toolbar}></div>
        {props.children}
      </MuiDrawer>
    </Hidden>
  </div>
)

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isSideBarOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default withStyles(styles, { withTheme: true })(Drawer)