/*eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'

const styles = theme => ({
  root: { marginBottom: theme.spacing.unit * 2 },
  formControl: {
    marginBottom: theme.spacing.unit,
    minWidth: 120,
  },
})

const Select = (props) => {
  const {
    name,
    label,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    options,
    classes,
    formControlProps,
    inputLabelProps,
    renderValue,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={true}
      {...formControlProps}
    >
      <InputLabel
        shrink={true}
        htmlFor={'id-' + name}
        {...inputLabelProps}
      >
        {label}
      </InputLabel>
      <MuiSelect
        className={classes.root}
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched && errors &&
          ((touched[name] && errors[name]) ? true : false)
        }
        inputProps={{
          name: name,
          id: 'id-' + name,
        }}
        renderValue={renderValue}
        {...custom}
      >
        {
          options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {
                option.photoSrc ?
                  <React.Fragment>
                    <ListItemAvatar>
                      <Avatar
                        src={option.photoSrc}
                        alt={option.label}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.label}
                    />
                  </React.Fragment>
                  :
                  option.label
              }
            </MenuItem>
          ))
        }
      </MuiSelect>
    </FormControl>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  renderValue: PropTypes.func,
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
}

export default withStyles(styles)(Select)