import React from 'react'
import PropTypes from 'prop-types'

import withService from './withService'

import LoadingIndicator from '../LoadingIndicator'
import ErrorMessage from '../ErrorMessage'

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
)

const withServiceLoaded = (serviceName, customQuery = {}) => (
  WrappedComponent => {
    const withCurrentService = withService(serviceName, customQuery)

    class WithServiceLoaded extends React.PureComponent {
      componentDidMount() {
        if (this.props._serviceData.loading) return

        this.props._find({
          limit: 1000000000,
          query: {},
        })
      }

      componentWillUnmount() {
        this.props._reset()
      }

      render() {
        return (
          this.props._serviceData.error ?
            <ErrorMessage
              message={'Wystąpił błąd!'}
              subMessage={'Nie można załadować danych!'}
            />
            :
            this.props._serviceData.loading ?
              <LoadingIndicator
                message={'Ładowanie...'}
              />
              :
              <WrappedComponent
                {...this.props}
              />
        )
      }
    }

    WithServiceLoaded.propTypes = {
      _serviceData: PropTypes.object.isRequired,
      _find: PropTypes.func.isRequired,
      _reset: PropTypes.func.isRequired,
    }

    const WrappedComponentWithService = (
      withCurrentService(WithServiceLoaded)
    )

    WrappedComponentWithService.displayName = (
      `WithServiceLoaded(${getDisplayName(WrappedComponent)})`
    )

    return WrappedComponentWithService
  }
)

export default withServiceLoaded