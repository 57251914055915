import React from 'react'

import Auth from './containers/Auth'
import Notifier from './containers/Notifier'

import AppInner from './AppInner'

const App = (props) => (
  <div>
    <Auth>
      <AppInner />
    </Auth>
    <Notifier />
  </div>
)

export default App
