import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../components/Forms/Button'
import FormsGenerator from '../../../components/Forms/Generator'
import PaperRefined from '../../../components/PaperRefined'

class Edit extends React.PureComponent {
  componentDidMount() {
    const id = this.props.match.params.id

    this.props._get(id)
  }

  componentWillUnmount() {
    this.props._reset()
  }

  render() {
    const id = this.props.match.params.id
    const data = this.props._serviceData.data || {}
    const fieldsArrayMappedToInitialValues = (
      this.props.fieldsArray.map(
        fieldObject => (
          Object.keys(data).includes(fieldObject.name) ?
            {
              ...fieldObject,
              initialValue: data[fieldObject.name],
            }
            :
            fieldObject.initialValueFrom ?
              {
                ...fieldObject,
                initialValue: (
                  fieldObject.initialValueFromMap ?
                    fieldObject.initialValueFromMap(
                      data[fieldObject.initialValueFrom]
                    )
                    :
                    data[fieldObject.initialValueFrom]
                ),
              }
              :
              fieldObject
        )
      )
    )

    return (
      <div>
        <Button
          label={'Wróć'}
          fullWidth={true}
          onClick={() => this.props._goToRoute(this.props.mainRoute)}
        />
        <PaperRefined
          title={this.props.title}
        >
          <FormsGenerator
            handleSubmit={data => {
              this.props.useUpdateInsteadOfPath ?
                this.props._update(
                  id,
                  { ...this.props._serviceData.data, ...data }
                )
                :
                this.props._patch(id, data)
            }}
            fieldsArray={fieldsArrayMappedToInitialValues}
            grid={this.props.grid}
            testMode={this.props.testMode}
            onChange={this.props.onChange}
            getFormikProps={this.props.getFormikProps}
          />
        </PaperRefined>
      </div>
    )
  }
}

Edit.propTypes = {
  match: PropTypes.object.isRequired,
  _serviceData: PropTypes.object.isRequired,
  _get: PropTypes.func.isRequired,
  _patch: PropTypes.func.isRequired,
  _update: PropTypes.func.isRequired,
  _reset: PropTypes.func.isRequired,
  _goToRoute: PropTypes.func.isRequired,
  mainRoute: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fieldsArray: PropTypes.array.isRequired,
  useUpdateInsteadOfPath: PropTypes.bool,
  grid: PropTypes.object,
  testMode: PropTypes.bool,
  onChange: PropTypes.func,
  getFormikProps: PropTypes.func,
}

export default Edit