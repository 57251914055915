import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

const TextTaxonomy = (props) => (
  <div
    style={{
      paddingBottom: 24,
    }}
  >
    <TextField
      label={'Wpisz wartość'}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth={true}
      value={props.value ? props.value[0] : ''}
      onChange={
        event => props.onChange(
          props.taxonomy.id,
          [event.target.value]
        )
      }
    />
  </div>
)

TextTaxonomy.propTypes = {
  onChange: PropTypes.func.isRequired,
  taxonomy: PropTypes.object.isRequired,
  value: PropTypes.array,
}

export default TextTaxonomy