import _ from 'lodash'

export const mergePredefinedTaxonomiesWithAlreadySet = (
  function (alreadySetTaxonomiesIds) {
    if (!this.props.predefinedTaxonomies) return alreadySetTaxonomiesIds

    const taxonomiesData = (
      this.props._serviceData.queryResult &&
      this.props._serviceData.queryResult.data
    )

    const predefinedTaxonomiesIds = (
      this.props.predefinedTaxonomies
        .map(
          predefinedTaxonomySlug => {
            const matchedTaxonomy = taxonomiesData.find(
              taxonomy => taxonomy.slug === predefinedTaxonomySlug
            )

            return (
              matchedTaxonomy &&
              matchedTaxonomy.id
            )
          }
        )
        .filter(predefinedTaxonomyId => predefinedTaxonomyId)
    )

    const allTaxonomies =_.uniq(
      alreadySetTaxonomiesIds.concat(predefinedTaxonomiesIds)
    )

    return allTaxonomies
  }
)

export const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

export const updateFormikState = function () {
  this.props.handleChange(
    createPseudoEvent(
      this.state.values,
      this.props.name,
    )
  )
}

export const onFormChange = function (event) {
  const pickedTaxonomies = event.target.value

  // delete values if taxonomy no longer is picked
  const values = _.pickBy(
    this.state.values,
    (value, key) => (
      pickedTaxonomies.includes(key)
    )
  )

  // if taxonomy is picked and was not already then
  // init with empty value ['']
  pickedTaxonomies.forEach(
    taxonomyId => {
      if (!values[taxonomyId]) {
        values[taxonomyId] = ['']
      }
    }
  )

  this.setState(
    { pickedTaxonomies, values },
    this.updateFormikState
  )
}

export const onTaxonomyChange = function (taxonomyId, values) {
  this.setState({
    values: {
      ...this.state.values,
      [taxonomyId]: values,
    },
  }, this.updateFormikState)
}