export const getFileSrc = (photoId, options = {}) => (
  window._env_.REACT_APP_URL_DOWNLOADER +
  '/' + (options.bucketName || 'photos') + '/' +
  photoId +
  (
    options.resize ?
      '?resize=' +
      JSON.stringify(options.resize)
      :
      ''
  )
)

export default getFileSrc