import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
})

const PaperRefined = (props) => (
  <div>
    <Paper
      className={props.classes.root}
      elevation={1}
    >
      {
        props.title ?
          <Typography
            className={props.classes.title}
            variant="h6"
            component="h6"
          >
            {props.title}
          </Typography>
          :
          null
      }
      <div>
        {props.children}
      </div>
    </Paper>
  </div>
)

PaperRefined.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
}

export default withStyles(styles)(PaperRefined)