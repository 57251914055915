import React from 'react'
import PropTypes from 'prop-types'

import { withServiceLoaded } from '../../ViewsGenerator'

import Select from '../Select'
import TaxonomyItem from './TaxonomyItem'

import {
  createPseudoEvent,
  updateFormikState,
  onFormChange,
  onTaxonomyChange,
  mergePredefinedTaxonomiesWithAlreadySet,
} from './TaxonomiesPicker.logic'

class TaxonomiesPicker extends React.Component {
  constructor(props) {
    super(props)

    this.updateFormikState = updateFormikState.bind(this)
    this.onFormChange = onFormChange.bind(this)
    this.onTaxonomyChange = onTaxonomyChange.bind(this)
    this.mergePredefinedTaxonomiesWithAlreadySet = (
      mergePredefinedTaxonomiesWithAlreadySet.bind(this)
    )

    const {
      name,
      values,
    } = props

    const taxonomies = values[name] || {}
    const alreadySetTaxonomiesIds = Object.keys(taxonomies)

    this.state = {
      pickedTaxonomies: (
        this.mergePredefinedTaxonomiesWithAlreadySet(alreadySetTaxonomiesIds)
      ),
      values: taxonomies,
    }
  }

  componentDidMount() {
    // update state to match predefinedTaxonomies if any
    this.onFormChange(createPseudoEvent(
      this.state.pickedTaxonomies,
      this.props.name
    ))
  }

  render() {
    const taxonomiesOptions = (
      this.props._serviceData.queryResult &&
      this.props._serviceData.queryResult.data.map(
        taxonomy => ({ value: taxonomy.id, label: taxonomy.name })
      )
    )

    return (
      <div>
        <Select
          name={'pickedTaxonomies'}
          values={{ pickedTaxonomies: this.state.pickedTaxonomies }}
          multiple={true}
          options={taxonomiesOptions}
          handleChange={this.onFormChange}
          formControlProps={{
            fullWidth: true,
          }}
          label={'Wybierz obsługiwane taksonomie'}
        />
        {
          this.state.pickedTaxonomies.map(
            taxonomyId => {
              const taxonomy = (
                this.props._serviceData.queryResult.data.find(
                  taxonomyData => taxonomyData.id === taxonomyId
                )
              )
              return (
                <TaxonomyItem
                  key={taxonomy.id}
                  value={this.state.values[taxonomy.id]}
                  onChange={this.onTaxonomyChange}
                  taxonomy={taxonomy}
                />
              )
            }
          )
        }
      </div>
    )
  }
}

TaxonomiesPicker.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  _serviceData: PropTypes.object.isRequired,
}

export default withServiceLoaded('taxonomies')(TaxonomiesPicker)