import React from 'react'
import PropTypes from 'prop-types'

import DialogWithButton from '../../DialogWithButton'
import TaxonomiesPicker from './TaxonomiesPicker'

class TaxonomiesPickerInDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div>
        {
          this.props.inDialog ?
            <DialogWithButton
              label={this.props.label}
              title={this.props.label}
            >
              <TaxonomiesPicker
                {...this.props}
              />
            </DialogWithButton>
            :
            <TaxonomiesPicker
              {...this.props}
            />
        }
      </div>
    )
  }
}

TaxonomiesPickerInDialog.propTypes = {
  label: PropTypes.string,
  inDialog: PropTypes.bool,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default TaxonomiesPickerInDialog