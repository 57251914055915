import React from 'react'

import TextTaxonomy from './TextTaxonomy'
import SelectTaxonomy from './SelectTaxonomy'
import SelectMultipleTaxonomy from './SelectMultipleTaxonomy'
import DateTimeTaxonomy from './DateTimeTaxonomy'
// import YearTaxonomy from './YearTaxonomy'

const YearTaxonomy = props => <DateTimeTaxonomy type={'year'} {...props} />

export default {
  'text': TextTaxonomy,
  'year': YearTaxonomy,
  'date': DateTimeTaxonomy,
  'select': SelectTaxonomy,
  'select-multiple': SelectMultipleTaxonomy,
}