import { warningSnackBar } from '../../../state/ui'

import { uploadFile } from '../../../db/upload/file'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

export const handleFileChange = function (e) {
  const file = e.target.files[0]

  if (!file) return

  if (file.size > 10 * 1024 * 1024) {
    this.props._dispatch(
      warningSnackBar(
        'Plik jest za duży! Maksymalna wielkość pliku to 10 MB!'
      )
    )
    return
  }

  this.setState({
    file,
    fileName: file.name,
  })

}

export const upload = function () {
  const formData = new FormData()

  formData.append('uploads[]', this.state.file, this.state.fileName)

  return uploadFile(
    formData,
    {
      bucketName: 'files',
      onUploadProgress: (p) => (
        this.setState({ completed: (p.loaded / p.total) * 100 })
      ),
    }
  )
    .then(data => {
      this.setState({
        completed: 0,
      })

      this.props.handleChange &&
        this.props.handleChange(createPseudoEvent(data, this.props.name))

      this.props.onSuccess && this.props.onSuccess(data)
    })
    .catch(err => this.props.onFailure && this.props.onFailure(err))
}