import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import DrawerItem from './DrawerItem'

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 6,
    backgroundColor: theme.palette.grey[100],
  },
})

class DrawerItemCollapse extends React.Component {
  state = {
    open: false,
  }

  componentDidMount(){
    const shouldBeVisible = (
      this.props.checkIfItIsOnPath(this.props.route.routes)
    )

    if(shouldBeVisible){
      this.setState({ open: true })
    }
  }

  render() {
    const childrenWithProps = React.Children.map(
      this.props.children,
      child => React.cloneElement(
        child,
        { className: this.props.classes.nested }
      )
    )

    return (
      <div>
        {
          this.props.route.sideBar.separatorAbove === true ?
            <Divider />
            :
            null
        }
        <DrawerItem
          route={this.props.route}
          isGroupOpen={this.state.open}
          handleGroupToggle={() => this.setState({ open: !this.state.open })}
          checkIfItIsOnPath={this.props.checkIfItIsOnPath}
        />
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {childrenWithProps}
        </Collapse>
      </div>
    )
  }
}

DrawerItemCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  checkIfItIsOnPath: PropTypes.func.isRequired,
}

export default withStyles(styles)(DrawerItemCollapse)