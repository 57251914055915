const queue = {}

export const addToQueue = (queueName, newAction) => {
  if (!queue[queueName]) {
    queue[queueName] = []
  }

  queue[queueName].push(newAction)

  return queue[queueName]
}

export const clearQueue = (queueName) => {
  queue[queueName] = []

  return queue[queueName]
}

export const getQueue = queueName => queue[queueName] || []

export const runQueue = (queueName, dispatch, doNotClearQueue = false) => {
  const queue = getQueue(queueName)

  queue.forEach(action => dispatch(action))

  if(doNotClearQueue) return
  
  clearQueue(queueName)
}