import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import { store } from './store'
import { Provider } from 'react-redux'

import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pl'

import App from './App'

import './index.css'

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
moment.locale('pl')

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale={'pl'}
        moment={moment}
      >
        <App />
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </Provider>
  ,
  document.getElementById('root')
)

serviceWorker.unregister()

window.processEnv = window._env_ /*eslint-env node*/
