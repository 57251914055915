
import React from 'react'
import PropTypes from 'prop-types'

import { withServiceLoaded } from '../../ViewsGenerator'

import FilesSelectInner from './FilesSelectInner'
import FileUpLoader from '../FileUpLoader'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

const FilesSelect = (props) => {
  const {
    name,
    formControlProps,
    inputLabelProps,
    label,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    renderValue,
    custom,
  } = props

  const files = (
    Array.isArray(props._serviceData.data.rows) ?
      props._serviceData.data.rows :
      []
  )

  const value = values[name]

  const onUploadSuccess = newFiles => {
    props._find({
      query: {
        $limit: 1000000000,
      },
    })
      .then(() => {
        handleChange(
          createPseudoEvent(newFiles[0].id, name)
        )
      })
  }

  return (
    <div>
      <FileUpLoader
        inDialog={true}
        onSuccess={onUploadSuccess}
        label={'Dodaj nowy plik'}
      />
      <FilesSelectInner
        value={value}

        name={name}
        formControlProps={formControlProps}
        inputLabelProps={inputLabelProps}
        label={label}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        renderValue={renderValue}
        options={files}
        custom={custom}
      />
    </div>
  )
}

FilesSelect.propTypes = {
  _find: PropTypes.func.isRequired,
  _serviceData: PropTypes.object.isRequired,
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  renderValue: PropTypes.func,
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  custom: PropTypes.object,
}

export default withServiceLoaded('files')(FilesSelect)