import axios from '../axios'

const URL_UPLOADER = window._env_.REACT_APP_URL_UPLOADER

export const uploadFile = (formData, options = {}) => {
  const url = URL_UPLOADER + '/' + (options.bucketName || 'photos')

  const accessToken = localStorage.getItem('accessToken')

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  }

  return axios.request({
    method: 'POST',
    url: url,
    data: formData,
    headers: headers,
    onUploadProgress: options && options.onUploadProgress,
  })
    .then(response => response.data[0])
}

export default uploadFile