import React from 'react'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiSwitch from '@material-ui/core/Switch'

const Switch = (props) => {
  const {
    name,
    label,
    values,
    handleChange,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  const value = (
    typeof values[name] === 'boolean' ?
      values[name]
      :
      typeof values[name] === 'string' && values[name] === 'false' ?
        false
        :
        Boolean(values[name])
  )

  return (
    <div>
      <FormControl component={'legend'}>{label}</FormControl>
      <FormControlLabel
        control={
          <MuiSwitch
            checked={value}
            onChange={handleChange}
            name={name}
            color={'primary'}
            {...custom}
          />
        }
        label={'Nie / Tak'}
      />
    </div>
  )
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
}

export default Switch