import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
  logInAsyncAction,
  sendForgotPasswordEmailAsyncAction,
} from '../../state/auth'

import Forms from './Forms'

const Auth = (props) => (
  props._isUserLoggedIn ?
    props.children
    :
    <Forms
      onLogInHandler={props._logIn}
      onForgotPasswordHandler={props._sendForgotPasswordEmail}
    />
)

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  _isUserLoggedIn: PropTypes.bool.isRequired,
  _logIn: PropTypes.func.isRequired,
  _sendForgotPasswordEmail: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  _isUserLoggedIn: state.auth.isUserLoggedIn,
})

const mapDispatchToProps = dispatch => ({
  _logIn: (email, password) => dispatch(logInAsyncAction(email, password)),
  _sendForgotPasswordEmail: (email) => (
    dispatch(sendForgotPasswordEmailAsyncAction(email))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth)