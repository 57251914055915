import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

const styles = theme => ({
  root: { marginBottom: theme.spacing.unit * 2 },
  formControl: {
    marginBottom: theme.spacing.unit,
    minWidth: 120,
  },
})

const FilesSelectInner = (props) => {
  const {
    name,
    classes,
    formControlProps,
    inputLabelProps,
    label,
    value,
    handleChange,
    handleBlur,
    touched,
    errors,
    options,
    custom,
  } = props

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={true}
      {...formControlProps}
    >
      <InputLabel
        shrink={true}
        htmlFor={'id-' + name}
        {...inputLabelProps}
      >
        {label}
      </InputLabel>
      <MuiSelect
        className={classes.root}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched && errors &&
          ((touched[name] && errors[name]) ? true : false)
        }
        inputProps={{
          name: name,
          id: 'id-' + name,
        }}
        {...custom}
      >
        {
          options.map(option => (
            <MenuItem
              key={option._id}
              value={option}
            >
              {option.originalname || option.filename}
            </MenuItem>
          ))
        }
      </MuiSelect>
    </FormControl>
  )
}

FilesSelectInner.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  custom: PropTypes.object,
}

export default withStyles(styles)(FilesSelectInner)