import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../components/Forms/Button'
import FormsGenerator from '../../../components/Forms/Generator'
import PaperRefined from '../../../components/PaperRefined'

const Create = (props) => (
  <div>
    <Button
      label={'Wróć'}
      fullWidth={true}
      onClick={() => props._goToRoute(props.mainRoute)}
    />
    <PaperRefined
      title={props.title}
    >
      <FormsGenerator
        handleSubmit={props._create}
        fieldsArray={props.fieldsArray}
        grid={props.grid}
        testMode={props.testMode}
        onChange={props.onChange}
        getFormikProps={props.getFormikProps}
      />
    </PaperRefined>
  </div>
)

Create.propTypes = {
  _goToRoute: PropTypes.func.isRequired,
  _create: PropTypes.func.isRequired,
  mainRoute: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fieldsArray: PropTypes.array.isRequired,
  grid: PropTypes.object,
  testMode: PropTypes.bool,
  onChange: PropTypes.func,
  getFormikProps: PropTypes.func,
}

export default Create