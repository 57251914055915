import { addToQueue, clearQueue, runQueue } from './actionsQueue'

const TOGGLE_SIDEBAR = 'ui/TOGGLE_SIDEBAR'
const ENQUEUE_SNACKBAR = 'ui/ENQUEUE_SNACKBAR'
const REMOVE_SNACKBAR = 'ui/REMOVE_SNACKBAR'
const OPEN_CONFIRM_DIALOG = 'ui/OPEN_CONFIRM_DIALOG'
const CLOSE_CONFIRM_DIALOG = 'ui/CLOSE_CONFIRM_DIALOG'
const ACCEPT_CONFIRM_DIALOG = 'ui/ACCEPT_CONFIRM_DIALOG'

export const toggleSideBarAction = () => ({ type: TOGGLE_SIDEBAR })

export const enqueueSnackbar = (message, options) => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: Date.now() + Math.random(),
    message,
    options,
  },
})
const makeEqSnack = options => msg => enqueueSnackbar(msg, options)
export const defaultSnackBar = makeEqSnack({ variant: 'default' })
export const errorSnackBar = makeEqSnack({ variant: 'error' })
export const successSnackBar = makeEqSnack({ variant: 'success' })
export const warningSnackBar = makeEqSnack({ variant: 'warning' })
export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
})

export const openConfirmDialogAction = (title, text, actionToQueue) => {
  if (actionToQueue) addToQueue('confirm-dialog', actionToQueue)

  return {
    type: OPEN_CONFIRM_DIALOG,
    title,
    text,
  }
}
export const closeConfirmDialogAction = () => {
  clearQueue('confirm-dialog')

  return { type: CLOSE_CONFIRM_DIALOG }
}
const acceptConfirmDialogAction = () => ({ type: ACCEPT_CONFIRM_DIALOG })
export const acceptConfirmDialogAsyncAction = () => (dispatch, getState) => {
  runQueue('confirm-dialog', dispatch)

  dispatch(acceptConfirmDialogAction())
}

export const initialState = {
  isSideBarOpen: false,
  isConfirmDialogOpen: false,
  confirmDialogTitle: '',
  confirmDialogText: '',
  isFullPageLoaderVisible: false,
  isMainRouterLoaderVisible: false,
  notifications: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      }
    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      }
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSideBarOpen: !state.isSideBarOpen,
      }
    case OPEN_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialogOpen: true,
        confirmDialogTitle: action.title,
        confirmDialogText: action.text,
      }
    case CLOSE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialogOpen: false,
        confirmDialogTitle: '',
        confirmDialogText: '',
      }
    case ACCEPT_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialogOpen: false,
        confirmDialogTitle: '',
        confirmDialogText: '',
      }
    default:
      return state
  }
}