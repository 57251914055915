import React from 'react'
import PropTypes from 'prop-types'

import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'
import './ReactMDEEditor.css'

import BackgroundFieldWrapper from '../../BackgroundFieldWrapper'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

const ReactMDEEditor = (props) => {
  const {
    name,
    value,
    handleChange,
  } = props

  const minEditorHeight = (window.innerHeight - 270)

  return (
    <BackgroundFieldWrapper
      style={{
        backgroundColor: '#fff',
        margin: 0,
        padding: 0,
      }}
    >
      <div className={'ReactMDEEditor'}>
        <ReactMde
          value={value}
          onChange={(value) => handleChange(createPseudoEvent(value, name))}
          minEditorHeight={minEditorHeight}
        />
      </div>
    </BackgroundFieldWrapper>
  )
}

ReactMDEEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
}

export default ReactMDEEditor