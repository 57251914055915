import React from 'react'
import PropTypes from 'prop-types'

import mapTypesToComponents from './mapTypesToComponents'

const Fields = (props) => {
  const {
    fieldsArray,
    grid, // eslint-disable-line
    ...custom
  } = props

  return (
    <React.Fragment>
      {
        fieldsArray.map((field, index) => {
          const FieldComponent = mapTypesToComponents[field.fieldType]
          const Wrapper = field.wrapperComponent || 'div'

          return (
            <Wrapper
              key={index}
              {...field.wrapperComponentProps}
            >
              <FieldComponent
                {...field.props}
                name={field.name}
                label={field.label}
                {...custom}
              />
            </Wrapper>
          )
        })
      }
    </React.Fragment>

  )
}

Fields.propTypes = {
  fieldsArray: PropTypes.array.isRequired,
}

export default Fields