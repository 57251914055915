import React from 'react'
import PropTypes from 'prop-types'

import Loader from './Loader'

import _ from 'lodash'

class StateHandler extends React.Component {
  shouldComponentUpdate(nextProps) {
    const request = (
      nextProps._serviceData &&
      nextProps._serviceData.request
    )

    const requestParams = request && request.params
    const requestPathVars = request && request.pathvars

    const restMethod = requestParams.method && requestParams.method.toLowerCase()

    const methodMap = {
      get: 'get',
      post: 'create',
      patch: 'patch',
      put: 'update',
      delete: 'remove',
    }

    const methodTmp = methodMap[restMethod]

    const method = (
      methodTmp === 'get' && !requestPathVars.id ?
        'find'
        :
        methodTmp
    )

    const states = _.pick(
      nextProps._serviceData,
      ['loading', 'error']
    )

    const truthyStates = _.pickBy(
      states,
      value => value
    )

    // pick ONLY error when error is truthy
    // to avoid notifying errors with finished
    const truthyStatesErrorChecked = _.pickBy(
      truthyStates,
      (value, key) => (
        truthyStates.error ?
          key === 'error'
          :
          true
      )
    )


    // eslint-disable-next-line max-params
    const call = (actions, method, state, caller) => {
      const actionToPerform = (
        actions &&
        actions[method] &&
        actions[method][state]
      )

      if (!actionToPerform) return

      if (Array.isArray(actionToPerform)) {
        actionToPerform.forEach(caller)
      } else {
        caller(actionToPerform)
      }
    }

    const state = (
      truthyStatesErrorChecked.error ?
        'error'
        :
        truthyStatesErrorChecked.loading ?
          'loading'
          :
          'finished'
    )

    call(
      nextProps.stateActions,
      method,
      state,
      action => this.props._dispatch(action(state))
    )
    call(
      nextProps.stateHandlers,
      method,
      state,
      handler => handler(state)
    )

    return true
  }

  render() {
    return (
      <Loader
        serviceData={this.props._serviceData}
      />
    )
  }
}

StateHandler.propTypes = {
  _dispatch: PropTypes.func.isRequired,
  _serviceData: PropTypes.object.isRequired,
  stateHandlers: PropTypes.object,
  stateActions: PropTypes.object,
}

export default StateHandler