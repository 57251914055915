import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'

const FileButton = (props) => (
  <div>
    <label
      htmlFor={props.id}
    >
      <Button
        component={'span'}
        fullWidth={props.fullWidth}
      >
        {props.children}
      </Button>
    </label>
    <input
      style={{ display: 'none' }}
      type={'file'}
      id={props.id}
      accept={props.accept}
      multiple={props.multiple}
      onChange={props.onChange}
    />
  </div>
)

FileButton.defaultProps = {
  fullWidth: true,
  multiple: false,
  id: 'raised-button-file',
  accept: 'image/jpg|image/jpeg|image/png',
}

FileButton.propTypes = {
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  id: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default FileButton