import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import Drawer from './Drawer'
import DrawerItem from './DrawerItem'
import DrawerItemCollapse from './DrawerItemCollapse'

const checkIfItIsOnPath = routeOrRoutes => {
  const routes = (
    Array.isArray(routeOrRoutes) ?
      routeOrRoutes
      :
      [routeOrRoutes]
  )

  const currentPath = window.location.pathname

  const visibleRoutes = routes.filter(
    route => (
      route &&
      route.path &&
      currentPath
        .split('/')
        .includes(
          route.path &&
          route.path.replace &&
          route.path.replace('/', '')
        )
    )
  )

  if (visibleRoutes.length === 0) return false

  return true
}

const SideBar = (props) => (
  <Drawer
    isSideBarOpen={props.isSideBarOpen}
    handleDrawerToggle={props.handleDrawerToggle}
  >
    <List>
      {
        props.routes
          .filter(route => route.sideBar)
          .map(
            route => (
              route.type === 'group' ?
                route.routes ?
                  <DrawerItemCollapse
                    key={'group-' + route.name}
                    route={route}
                    checkIfItIsOnPath={checkIfItIsOnPath}
                  >
                    {
                      route.routes.map(
                        route => (
                          <DrawerItem
                            key={route.path}
                            route={route}
                            goToRoute={props.goToRoute}
                            handleDrawerToggle={props.handleDrawerToggle}
                            checkIfItIsOnPath={checkIfItIsOnPath}
                          />
                        )
                      )
                    }
                  </DrawerItemCollapse>
                  :
                  null
                :
                <DrawerItem
                  key={route.path}
                  route={route}
                  goToRoute={props.goToRoute}
                  handleDrawerToggle={props.handleDrawerToggle}
                  checkIfItIsOnPath={checkIfItIsOnPath}
                />
            )
          )
      }
    </List>
  </Drawer>
)

SideBar.propTypes = {
  classes: PropTypes.object,
  routes: PropTypes.array.isRequired,
  goToRoute: PropTypes.func.isRequired,
  isSideBarOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
}

export default SideBar