import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { history } from '../../store'
import { ConnectedRouter } from 'connected-react-router'

import RouteLoader from '../../components/RouteLoader'
import PrivilegeChecker from '../PrivilegeChecker'
import RouteNotFound from '../RouteNotFound'

const makeRouteComponent = route => {
  const RouteComponent = props => {
    const Component = route.component

    return (
      <PrivilegeChecker
        privilegesToCheck={route.privilegesToCheck}
      >
        <Component {...props} />
      </PrivilegeChecker>
    )
  }

  return RouteComponent
}

const makeRoute = (props, route, path) => (
  <Route
    exact={path ? true : false}
    key={path || route.path}
    path={path || route.path}
    component={makeRouteComponent(route)}
  />
)

const MainRouter = (props) => {
  const routes = props.routes

  const routesFlatten = routes.reduce(
    (r, route) => (
      route.type === 'group' ?
        route.routes ?
          [...r, ...route.routes]
          :
          r
        :
        [...r, route]
    ),
    []
  )

  return (
    <Suspense fallback={<RouteLoader />}>
      <ConnectedRouter history={history}>
        <Switch>
          {
            routesFlatten.map(route => (
              Array.isArray(route.path) ?
                route.path.map(path => (
                  makeRoute(props, route, path)
                ))
                :
                makeRoute(props, route)
            ))
          }
          <Route component={RouteNotFound} />
        </Switch>
      </ConnectedRouter>
    </Suspense>
  )
}

MainRouter.propTypes = {
  routes: PropTypes.array.isRequired,
}

export default MainRouter