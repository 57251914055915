import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'

import getFileSrc from '../../../utils/getFileSrc'

const styles = theme => ({
  root: { marginBottom: theme.spacing.unit * 2 },
  formControl: {
    marginBottom: theme.spacing.unit,
    minWidth: 120,
  },
})

const renderSelectItem = (value) => {
  const photoSrc = getFileSrc(value._id, { resize: { height: 100 } })
  const label = value.originalname || value.filename

  return (
    <MenuItem
      key={value._id}
      value={value}
    >
      {
        photoSrc ?
          <React.Fragment>
            <ListItemAvatar>
              <Avatar
                style={{ backgroundColor: 'rgb(250, 250, 250)' }}
                src={photoSrc}
                alt={label}
              />
            </ListItemAvatar>
            <ListItemText
              primary={label}
            />
          </React.Fragment>
          :
          label
      }
    </MenuItem>
  )
}

const PhotosSelect = (props) => {
  const {
    name,
    classes,
    formControlProps,
    inputLabelProps,
    label,
    value,
    handleChange,
    handleBlur,
    touched,
    errors,
    options,
    custom,
  } = props

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={true}
      {...formControlProps}
    >
      <InputLabel
        shrink={true}
        htmlFor={'id-' + name}
        {...inputLabelProps}
      >
        {label}
      </InputLabel>
      <MuiSelect
        className={classes.root}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched && errors &&
          ((touched[name] && errors[name]) ? true : false)
        }
        inputProps={{
          name: name,
          id: 'id-' + name,
        }}
        renderValue={renderSelectItem}
        {...custom}
      >
        {
          options.map(file => renderSelectItem(file))
        }
      </MuiSelect>
    </FormControl>
  )
}

PhotosSelect.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  custom: PropTypes.object,
}

export default withStyles(styles)(PhotosSelect)