import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import DialogWithButton from '../../../components/DialogWithButton'
import UpLoaderInner from './UpLoaderInner'

import {
  handleFileChange,
  upload,
} from './UpLoader.logic'

class UpLoader extends React.Component {
  constructor(props) {
    super(props)

    this.handleFileChange = handleFileChange.bind(this)
    this.upload = upload.bind(this)

    this.state = {
      file: null,
      fileName: null,
      getCroppedCanvas: null,
      completed: 0,
    }
  }

  render() {
    const {
      inDialog,
      label,
      // can be cropper.js props
      ...rest
    } = this.props

    const upLoaderInner = (
      <UpLoaderInner
        completed={this.state.completed}
        file={this.state.file}
        handleFileChange={this.handleFileChange}
        upload={this.upload}
        {...rest}
      />
    )

    return (
      inDialog ?
        <DialogWithButton
          label={label}
          title={label}
          closeDialogProp={'onSuccess'}
        >
          {upLoaderInner}
        </DialogWithButton>
        :
        upLoaderInner
    )
  }
}

UpLoader.defaultProps = {
  inDialog: true,
}

UpLoader.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  inDialog: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

export default connect(
  null,
  dispatch => ({
    _dispatch: (...all) => dispatch(...all),
  })
)(UpLoader)