import React from 'react'
import PropTypes from 'prop-types'

import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import UpIcon from '@material-ui/icons/ArrowUpward'
import DownIcon from '@material-ui/icons/ArrowDownward'

const Item = (props) => {
  const spaceForIcons = (
    (props.moveDown ? 40 : 0) +  
    (props.moveUp ? 40 : 0) +  
    (props.onDeleteClick ? 40 : 0)
  )

  return (
    <ListItem>
      <ListItemText
        style={{
          maxWidth: `calc(100% - ${spaceForIcons}px)`,
          overflow: 'hidden',
        }}
      >
        <TextField
          name={props.option.value}
          value={props.option.label}
          fullWidth={true}
          onChange={event => props.handleEdit(props.option.value, event)}
        />
      </ListItemText >
      <ListItemSecondaryAction>
        {
          props.moveDown ?
            <IconButton
              disabled={props.index === props.optionsLength - 1}
              onClick={() => props.moveDown(props.index)}
            >
              <DownIcon />
            </IconButton>
            :
            null
        }
        {
          props.moveUp ?
            <IconButton
              disabled={props.index === 0}
              onClick={() => props.moveUp(props.index)}
            >
              <UpIcon />
            </IconButton>
            :
            null
        }
        <IconButton
          onClick={() => props.onDeleteClick(props.option.value)}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

Item.propTypes = {
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  onDeleteClick: PropTypes.func,
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  optionsLength: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
}

export default Item