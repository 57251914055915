/* eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Tooltip from '@material-ui/core/Tooltip'
import Collapse from '@material-ui/core/Collapse'

import UpIcon from '@material-ui/icons/ArrowUpward'
import DownIcon from '@material-ui/icons/ArrowDownward'
import Delete from '@material-ui/icons/Delete'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import BackgroundFieldWrapper from '../BackgroundFieldWrapper'
import Fields from '../Generator/Fields'
import Button from '../Button'

import {
  makeNewRowData,
  updateFormikState,
  handleInnerChange,
  addRow,
  removeRow,
  moveUp,
  moveDown,
  toggleCollapse,
} from './ArrayOfFields.logic'

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    flexBasis: '33.33333%',
  },
}

class ArrayOfFields extends React.Component {
  constructor(props) {
    super(props)

    this.makeNewRowData = makeNewRowData.bind(this)
    this.updateFormikState = updateFormikState.bind(this)
    this.handleInnerChange = handleInnerChange.bind(this)
    this.addRow = addRow.bind(this)
    this.removeRow = removeRow.bind(this)
    this.moveUp = moveUp.bind(this)
    this.moveDown = moveDown.bind(this)
    this.toggleCollapse = toggleCollapse.bind(this)

    const currentValue = (
      this.props.values[this.props.name]
    )

    this.state = {
      fieldsValues: (
        currentValue ?
          currentValue
          :
          [this.makeNewRowData()]
      ),
      collapsedIn: {},
    }
  }

  makeNewRowData = () => {
    const key = Date.now()

    const result = this.props.array.reduce(
      (r, field) => ({
        ...r,
        [field.name]: field.initialValue,
      }),
      {}
    )

    result._key = key

    return result
  }

  render() {
    const HeaderWrapper = this.props.headerWrapper || 'div'
    const FieldWrapper = this.props.fieldWrapper || BackgroundFieldWrapper
    const CollapseComponent = this.props.collapsible ? Collapse : 'div'

    return (
      <div>
        <HeaderWrapper>
          <h3>{this.props.label}</h3>
          <Button
            fullWidth={true}
            onClick={this.addRow}
          >
            {this.props.buttonLabel}
          </Button>
        </HeaderWrapper>
        {
          this.state.fieldsValues.map(
            (fieldSetValues, index) => {
              const fieldTitle = (
                this.props.title &&
                this.state.fieldsValues[index] &&
                _.get(this.state.fieldsValues[index], this.props.title)
              )
              const key = fieldSetValues._key
              const collapsedIn = this.state.collapsedIn[key]

              return (
                <div key={key}>
                  <FieldWrapper>
                    <div style={styles.buttonContainer}>
                      <Tooltip title={this.props.buttonDeleteLabel}>
                        <Button
                          style={styles.button}
                          color={'default'}
                          variant={'text'}
                          onClick={this.removeRow(index)}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Przesuń pozycję w górę'}>
                        <Button
                          style={styles.button}
                          color={'default'}
                          variant={'text'}
                          disabled={index === 0}
                          onClick={() => this.moveUp(index)}
                        >
                          <UpIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Przesuń pozycję w dół'}>
                        <Button
                          style={styles.button}
                          color={'default'}
                          variant={'text'}
                          disabled={
                            index === this.state.fieldsValues.length - 1
                          }
                          onClick={() => this.moveDown(index)}
                        >
                          <DownIcon />
                        </Button>
                      </Tooltip>
                    </div>
                    {
                      this.props.collapsible ?
                        <div>
                          <Tooltip title={collapsedIn ? 'Zwiń' : 'Rozwiń'}>
                            <Button
                              fullWidth={true}
                              style={styles.button}
                              color={'default'}
                              variant={'text'}
                              onClick={() => this.toggleCollapse(key)}
                            >
                              {
                                fieldTitle ?
                                  fieldTitle
                                  :
                                  null
                              }
                              {
                                collapsedIn ?
                                  <ExpandLess />
                                  :
                                  <ExpandMore />
                              }
                            </Button>
                          </Tooltip>
                          {
                            collapsedIn ?
                              <div>
                                <br />
                              </div>
                              :
                              null
                          }
                        </div>
                        :
                        null
                    }
                    <CollapseComponent in={collapsedIn}>
                      <Fields
                        fieldsArray={this.props.array}
                        values={this.state.fieldsValues[index]}
                        touched={{}}
                        errors={{}}
                        handleChange={this.handleInnerChange(index)}
                      />
                    </CollapseComponent>

                  </FieldWrapper>
                </div>
              )
            }
          )
        }
      </div>
    )
  }
}

ArrayOfFields.propTypes = {
  headerWrapper: PropTypes.func,
  fieldWrapper: PropTypes.func,
  label: PropTypes.string,
  title: PropTypes.string,
  collapsible: PropTypes.bool,
  values: PropTypes.object.isRequired,
  array: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDeleteLabel: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}

ArrayOfFields.defaultProps = {
  buttonLabel: 'Dodaj wpis',
  buttonDeleteLabel: 'Usuń wpis',
}

export default ArrayOfFields