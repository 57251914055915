import React from 'react'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import { CirclePicker } from 'react-color'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

const ColorPicker = (props) => {
  const {
    name,
    label,
    values,
    handleChange,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '30px 0',
      }}
    >
      <FormControl component={'legend'}>
        {label}
      </FormControl>
      <br />
      <CirclePicker
        color={values[name]}
        onChange={
          (color, event) => (
            handleChange(
              createPseudoEvent(color.hex, name)
            )
          )
        }
        {...custom}
      />
    </div>
  )
}

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
}

export default ColorPicker