export default gridArray => (
  gridArray.reduce(
    (r, rowArr) => {
      const rowLength = rowArr.length

      if (rowLength === 0) return r

      if (12 % rowLength !== 0) throw Error('Cant divide 12 to make grid!')

      const colWidth = 12 / rowLength

      const rowObjArr = rowArr.reduce(
        (r, cell, i, arr) => { // eslint-disable-line max-params
          const prevCell = arr[i - 1]

          if (prevCell === cell) {
            const [prevCellObj] = r.slice(-1)
            const restCellObjs = r.slice(0, -1)

            return [
              ...restCellObjs,
              {
                ...prevCellObj,
                cols: prevCellObj.cols + colWidth,
              },
            ]
          }

          return r.concat({
            component: cell,
            cols: colWidth,
          })
        },
        []
      )

      return [
        ...r,
        ...rowObjArr,
      ]
    },
    []
  )
)