/* eslint-disable max-lines */
import React from 'react'

const makeRoutes = (data) => {
  const collectionsData = data && data.collectionsData
  const userPrivileges = data && data.userData && data.userData.privileges

  return [
    {
      name: 'Home',
      path: ['/', '/dashboard'],
      component: React.lazy(() => import('./views/DashboardView')),
      icon: React.lazy(() => import('@material-ui/icons/Dashboard')),
      sideBar: {
        separatorAbove: false,
        separatorBelow: true,
      },
    },
    ...(
      userPrivileges.includes('superadmin') ?
        [{
          name: 'Użytkownicy',
          path: '/users',
          component: React.lazy(() => import('./views/UsersView')),
          icon: React.lazy(() => import('@material-ui/icons/Person')),
          sideBar: {
            separatorAbove: false,
            separatorBelow: true,
          },
        }]
        :
        []
    ),
    {
      name: 'Pliki',
      path: '/files',
      component: React.lazy(() => import('./views/FilesView')),
      icon: React.lazy(() => import('@material-ui/icons/Attachment')),
      sideBar: {
        separatorAbove: false,
        separatorBelow: true,
      },
    },
    {
      name: 'Zdjęcia',
      path: '/photos',
      component: React.lazy(() => import('./views/PhotosView')),
      icon: React.lazy(() => import('@material-ui/icons/Photo')),
      sideBar: {
        separatorAbove: false,
        separatorBelow: true,
      },
    },
    {
      type: 'group',
      name: 'Kolekcje',
      icon: React.lazy(() => import('@material-ui/icons/DataUsage')),
      sideBar: {
        separatorAbove: false,
        separatorBelow: true,
      },
      routes: (
        collectionsData &&
        data.collectionsData
          .filter(filterRoutesWithoutAccess(userPrivileges))
          .map(makeCollectionRoute)
          .sort((a, b) => a.name.localeCompare(b.name))
      ),
    },
  ]
}

const filterRoutesWithoutAccess = (userPrivileges) => (collectionData) => {
  if (!collectionData.requiredPrivileges) return true
  if (!collectionData.requiredPrivileges.list) return true

  return collectionData.requiredPrivileges.list
    .find(routePrivilege => userPrivileges.includes(routePrivilege))
}

const makeCollectionRoute = (collectionData) => ({
  name: collectionData.displayName || collectionData.name,
  path: '/collections/' + collectionData.name,
  component: React.lazy(() => import('./views/CollectionView')),
  icon: React.lazy(() => import('@material-ui/icons/Label')),
  sideBar: {
    separatorAbove: false,
    separatorBelow: true,
  },
})

export default makeRoutes