import React from 'react'
import PropTypes from 'prop-types'

import CropperJS from 'react-cropper'
import 'cropperjs/dist/cropper.css'

class Cropper extends React.Component {
  cropperRef = React.createRef()

  componentDidMount() {
    this.props.getCroppedCanvasFunc(
      (type = 'image/jpeg') => {
        return this.cropperRef.current.getCroppedCanvas().toDataURL(type)
      }
    )
  }

  render() {
    const {
      getCroppedCanvasFunc, // eslint-disable-line no-unused-vars
      onCrop,
      src,
      style,
      // can be cropper.js options
      ...rest
    } = this.props

    return (
      <div>
        <CropperJS
          ref={this.cropperRef}
          src={src}
          style={{
            height: 400,
            width: '100%',
            ...style,
          }}
          crop={onCrop}
          aspectRatio={4 / 3}
          {...rest}
        />
      </div>
    )
  }
}

Cropper.propTypes = {
  getCroppedCanvasFunc: PropTypes.func.isRequired,
  onCrop: PropTypes.func,
  src: PropTypes.string,
  style: PropTypes.object,
}

export default Cropper