import React from 'react'
import PropTypes from 'prop-types'

import DateTimePicker from '../../../DateTimePicker'

const DateTimeTaxonomy = (props) => {
  const setNow = () => {
    const date = new Date()
    date.setUTCHours(0, 0, 0, 0)

    if(props.type === 'year'){
      date.setUTCMonth(0, 1)
    }

    const now = date.toISOString()

    props.onChange(
      props.taxonomy.id,
      [now]
    )

    return now
  }

  return (
    <div
      style={{
        paddingBottom: 24,
      }}
    >
      <DateTimePicker
        label={`Wartość taksonomii ${props.taxonomy.name}`}
        name={'datetime'}
        type={props.type}
        formControlProps={{
          fullWidth: true,
        }}
        values={{
          datetime: (
            props.value ?
              props.value[0] || setNow()
              :
              (new Date()).toISOString()
          ),
        }}
        handleChange={
          event => props.onChange(
            props.taxonomy.id,
            [event.target.value]
          )
        }
      />
    </div>
  )
}

DateTimeTaxonomy.propTypes = {
  onChange: PropTypes.func.isRequired,
  taxonomy: PropTypes.object.isRequired,
  type: PropTypes.string,
  value: PropTypes.array,
}

export default DateTimeTaxonomy