import validUrl from 'valid-url'

export default ({
  requiredCheck,
  emailCheck,
  urlCheck,
  numberCheck,
}) => (values, props) => {
  const errors = {}

  // higher placed checks are first to be displayed as erros
  // do not displaying multiple errors at once
  requiredCheck && requiredCheck.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Pole wymagane!'
    }
  })

  emailCheck && emailCheck.forEach(field => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field])) {
      errors[field] = (
        errors[field] ?
          errors[field]
          :
          'Niepoprawny adres e-mail!'
      )
    }
  })

  urlCheck && urlCheck.forEach(field => {
    if (!validUrl.isWebUri(values[field])) {
      errors[field] = 'Podaj poprawny adres URL!'
    }
  })

  numberCheck && numberCheck.forEach(field => {
    if (Number.isInteger(Number(values[field]))) {
      errors[field] = 'To pole nie może być liczbą!'
    }
  })

  return errors
}