export default (
  fieldsObj => fieldsObj.reduce(
    (reduced, fieldObj) => (
      Object.keys(fieldObj.validate || {}).reduce(
        (reducedPrim, key) => ({
          ...reducedPrim,
          [key]: (
            reducedPrim[key] ?
              reducedPrim[key].concat(fieldObj.name)
              :
              [fieldObj.name]
          ),
        }),
        reduced
      )
    ),
    {}
  )
)