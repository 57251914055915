import React from 'react'
import PropTypes from 'prop-types'

import mapTypesToComponents from './mapTypesToComponents'

const TaxonomyItem = (props) => {
  const TaxonomyComponent = mapTypesToComponents[props.taxonomy.type]

  return (
    <div>
      <h4
        style={{
          margin: '0.75rem 0',
        }}
      >
        {props.taxonomy.name}
      </h4>
      <TaxonomyComponent
        {...props}
      />
    </div>
  )
}

TaxonomyItem.propTypes = {
  taxonomy: PropTypes.object.isRequired,
}

export default TaxonomyItem