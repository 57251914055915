import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import generateGridObjArr from './generateGridObjArr'
import mapGridArraysToBreakPoints from './mapGridArraysToBreakPoints'

const GirdCreator = (props) => {
  const breakPoints = ['xs', 'sm', 'md', 'lg', 'xl']
  const mappedGridArraysToBreakPoints = (
    mapGridArraysToBreakPoints(breakPoints, props)
  )

  const makeInnerGrid = gridArr => (
    gridArr &&
    generateGridObjArr(gridArr)
      .map(
        (colElement, index) => {
          const Wrapper = props.wrapperComponent
          const content = (
            Wrapper ?
              <Wrapper>
                {colElement.component}
              </Wrapper>
              :
              colElement.component
          )

          return (
            <Grid
              key={index}
              item={true}
              xs={colElement.cols}
              {...props.gridItemProps}
            >
              {content}
            </Grid>
          )
        }
      )
  )

  return (
    <Grid
      container={true}
      {...props.containerProps}
    >
      {
        breakPoints
          .map(
            breakPoint => (
              <Hidden
                key={breakPoint}
                only={
                  breakPoints.filter(
                    currentBreakPoint => currentBreakPoint !== breakPoint
                  )
                }
              >
                {makeInnerGrid(mappedGridArraysToBreakPoints[breakPoint])}
              </Hidden>
            )
          )
      }
    </Grid>
  )
}

const gridArrPropType = (
  PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ])
    )
  )
)

GirdCreator.propTypes = {
  xs: gridArrPropType.isRequired,
  sm: gridArrPropType,
  md: gridArrPropType,
  lg: gridArrPropType,
  xl: gridArrPropType,
  gridItemProps: PropTypes.object,
  containerProps: PropTypes.object,
  wrapperComponent: PropTypes.func,
}

export default GirdCreator