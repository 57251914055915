import { openConfirmDialogAction } from '../../../state/ui'
import moveArrayElement from '../../../utils/moveArrayElement'

import { store } from '../../../store'

const createPseudoEvent = (value, name) => ({
  target: {
    name,
    value,
  },
})

export const moveUp = function (index) {
  this.setState({
    fieldsValues: moveArrayElement(
      this.state.fieldsValues,
      index,
      -1
    ),
  }, this.updateFormikState)
}
export const moveDown = function (index) {
  this.setState({
    fieldsValues: moveArrayElement(
      this.state.fieldsValues,
      index,
      1
    ),
  }, this.updateFormikState)
}

export const makeNewRowData = function () {
  const key = Date.now()

  const result = this.props.array.reduce(
    (r, field) => ({
      ...r,
      [field.name]: field.initialValue,
    }),
    {}
  )

  result._key = key

  return result
}

export const updateFormikState = function () {
  this.props.handleChange(
    createPseudoEvent(this.state.fieldsValues, this.props.name)
  )
}

export const handleInnerChange = function (index) {
  return event => {
    const targetType = event.target.type
    const name = event.target.name
    const value = targetType === 'checkbox' ?
      event.target.checked
      :
      event.target.value

    this.setState(
      prevState => (
        {
          fieldsValues: prevState.fieldsValues.map(
            (fieldSet, filedSetIndex) => (
              filedSetIndex === index ?
                {
                  ...fieldSet,
                  [name]: value,
                }
                :
                fieldSet
            )
          ),
        }
      ),
      this.updateFormikState
    )
  }
}

export const addRow = function () {
  this.setState(
    prevState => ({
      fieldsValues: [this.makeNewRowData()].concat(prevState.fieldsValues),
    }),
    this.updateFormikState
  )
}

export const removeRow = function (index) {
  return () => {
    const remove = () => this.setState(
      prevState => ({
        fieldsValues: prevState.fieldsValues.filter(
          (fieldSetValue, i) => index !== i
        ),
      }),
      this.updateFormikState
    )

    store.dispatch(
      openConfirmDialogAction(
        'Potwierdź usunięcie!',
        'Usunięcie będzie miało trwały skutek, gdy zapiszesz zmiany.',
        () => remove()
      )
    )
  }
}

export const toggleCollapse = function (key) {
  this.setState(prevState => ({
    collapsedIn: {
      ...prevState.collapsedIn,
      [key]: !prevState.collapsedIn[key],
    },
  }))
}