import React from 'react'
import PropTypes from 'prop-types'

import MuiRadio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

const styles = {
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}

const Radio = (props) => {
  const {
    name,
    label,
    values,
    handleChange,
    options,
    formControlProps,
    inputLabelProps,
    radioGroupProps,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleBlur,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <FormControl
      component={'fieldset'}
      {...formControlProps}
    >
      <FormLabel
        component={'legend'}
        {...inputLabelProps}
      >
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        value={values[name]}
        onChange={handleChange}
        style={styles.radioGroup}
        {...radioGroupProps}
      >
        {
          options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              control={
                <MuiRadio
                  {...custom}
                />
              }
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}

Radio.propTypes = {
  label: PropTypes.string,
  handleBlur: PropTypes.func,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  radioGroupProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}

export default Radio