import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../components/Forms/Button'
import Table from '../../../components/Table'

class List extends React.PureComponent {
  state = {
    pageSize: 10,
  }

  componentDidMount() {
    this.getPage()
  }

  componentWillUnmount() {
    this.props._reset()
  }

  getPage = (page = 0) => (
    this.props._getPage(page, this.state.pageSize)
  )

  render() {
    const arrayFilledWithEmptyPages = (
      Array(
        (
          this.props._serviceData.data &&
          this.props._serviceData.data.page &&
          this.props._serviceData.data.pageSize &&
          (
            (this.props._serviceData.data.page - 1) *
            this.props._serviceData.data.pageSize
          )
        ) || 0
      )
        .fill({ name: '' })
        .concat(
          (
            this.props._serviceData.data &&
            this.props._serviceData.data.rows
          ) || []
        )
    )

    const availableActions = [
      {
        type: 'edit',
        icon: 'edit',
        tooltip: 'Edytuj',
        onClick: (event, rowData) => {
          this.props._goToRoute(
            this.props.mainRoute + '/edit/' + rowData._id
          )
        },
      },
      {
        type: 'delete',
        icon: 'delete',
        tooltip: 'Usuń',
        onClick: (event, rowData) => {
          this.props._removeWithDialog(rowData._id)
        },
      },
    ]

    const enabledActions = availableActions.filter(
      action => this.props.enabledActions.includes(action.type)
    )

    return (
      <div>
        {
          this.props.create ?
            <Button
              label={this.props.create.btnTitle}
              fullWidth={true}
              onClick={
                () => this.props._goToRoute(this.props.mainRoute + '/create')
              }
            />
            :
            null
        }
        <Table
          columns={this.props.columns}
          detailPanel={this.props.detailPanel}
          actions={enabledActions}
          data={arrayFilledWithEmptyPages}
          total={
            this.props._serviceData.data &&
            this.props._serviceData.data.total
          }
          title={this.props.title}
          onChangePage={this.getPage}
          onChangeRowsPerPage={pageSize => {
            this.setState({ pageSize }, this.getPage)
          }}
        />
      </div>
    )
  }
}

List.propTypes = {
  _serviceData: PropTypes.object.isRequired,
  _getPage: PropTypes.func.isRequired,
  _removeWithDialog: PropTypes.func.isRequired,
  _reset: PropTypes.func.isRequired,
  _goToRoute: PropTypes.func.isRequired,
  _remove: PropTypes.func.isRequired,
  mainRoute: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  enabledActions: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  detailPanel: PropTypes.func,
  create: PropTypes.object,
  customQuery: PropTypes.object,
}

List.defaultProps = {
  enabledActions: [],
}

export default List