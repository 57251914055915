import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'

const styles = theme => ({
  button: {
    marginBottom: theme.spacing.unit,
  },
})

const Button = (props) => {
  const {
    classes,
    children,
    label,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    name,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    options,
    formControlProps,
    inputLabelProps,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <MuiButton
      variant="contained"
      color="primary"
      className={classes.button}
      {...custom}
    >
      {
        label ?
          label
          :
          children
      }
    </MuiButton>
  )
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
}

export default withStyles(styles)(Button)