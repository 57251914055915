import { enqueueSnackbar } from '../../state/ui'
import { push } from 'connected-react-router'

const defaultSnackBar = msg => enqueueSnackbar(msg, { variant: 'default' })
const errorSnackBar = msg => enqueueSnackbar(msg, { variant: 'error' })
const successSnackBar = msg => enqueueSnackbar(msg, { variant: 'success' })
const warningSnackBar = msg => enqueueSnackbar(msg, { variant: 'warning' })

const defaultError = [
  err => {
    console.error(err) // eslint-disable-line no-console
    return errorSnackBar('Coś poszło nie tak!')
  },
]

const defaultErrorAndReload = defaultError.concat([
  // @HACK push again same route to load data again
  // prevents to display form without data after error
  () => push(),
])



const createDefaultStateActions = mainPath => ({
  find: {
    error: defaultError,
    finished: [
      () => defaultSnackBar('Zakończono pobieranie'),
    ],
  },
  get: {
    error: defaultError,
    finished: () => defaultSnackBar('Pobrano rekord'),
  },
  create: {
    error: defaultError,
    finished: [
      () => successSnackBar('Utworzono rekord'),
      () => push(mainPath),
    ],
  },
  patch: {
    error: defaultError,
    finished: [
      () => warningSnackBar('Zedytowano rekord'),
      () => push(mainPath),
    ],
  },
  update: {
    error: defaultErrorAndReload,
    finished: [
      () => warningSnackBar('Nadpisano rekord'),
      () => push(mainPath),
    ],
  },
  remove: {
    error: defaultError,
    finished: [
      () => errorSnackBar('Usunięto rekord'),
      () => push(mainPath),
    ],
  },
})

export default createDefaultStateActions