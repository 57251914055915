import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { toggleSideBarAction } from '../../state/ui'
import { logOutAsyncAction } from '../../state/auth'
import { push } from 'connected-react-router'

import { withStyles } from '@material-ui/core/styles'

import AppBar from './AppBar'
import SideBar from './SideBar'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appbar: {
    zIndex: 99999,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
    overflowX: 'hidden',
    position: 'relative',
  },
})

const Main = props => (
  <main className={props.classes.content}>
    <div className={props.classes.toolbar}></div>
    {props.children}
  </main>
)

Main.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

const MainMemorized = React.memo(
  Main,
  (prevProps, nextProps) => {
    return prevProps.children === nextProps.children
  }
)

const Navigation = (props) => (
  <div className={props.classes.root}>
    <AppBar
      className={props.classes.appbar}
      handleDrawerToggle={props._toggleSideBarAction}
      handleLogOut={props._logOut}
    />

    <SideBar
      routes={props.routes}
      isSideBarOpen={props._isSideBarOpen}
      handleDrawerToggle={props._toggleSideBarAction}
      goToRoute={props._goToRoute}
    />

    <MainMemorized
      {...props}
    />

  </div>
)

Navigation.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  _toggleSideBarAction: PropTypes.func.isRequired,
  _goToRoute: PropTypes.func.isRequired,
  _logOut: PropTypes.func.isRequired,
  _isSideBarOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  _isSideBarOpen: state.ui.isSideBarOpen,
})

const mapDispatchToProps = dispatch => ({
  _toggleSideBarAction: () => dispatch(toggleSideBarAction()),
  _goToRoute: path => dispatch(push(path)),
  _logOut: () => dispatch(logOutAsyncAction()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navigation))