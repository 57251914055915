import React from 'react'
import PropTypes from 'prop-types'

import GridGenerator from '../../GirdCreator'

import mapTypesToComponents from './mapTypesToComponents'
import mapFormComponentsToGrid from './mapFormComponentsToGrid'

const Fields = (props) => {
  const {
    fieldsArray,
    grid,
    ...custom
  } = props

  const breakPoints = ['xs', 'sm', 'md', 'lg', 'xl']

  const componentsArray = fieldsArray.map((field, index) => {
    const FieldComponent = mapTypesToComponents[field.fieldType]
    const Wrapper = field.wrapperComponent || 'div'

    return {
      name: field.name,
      element: (
        <Wrapper
          {...field.wrapperComponentProps}
        >
          <FieldComponent
            {...field.props}
            key={index}
            name={field.name}
            label={field.label}
            {...custom}
          />
        </Wrapper>
      ),
    }
  })

  const gridGeneratorProps = breakPoints.reduce(
    (r, breakPoint) => ({
      ...r,
      [breakPoint]: (
        grid[breakPoint] &&
        mapFormComponentsToGrid(grid[breakPoint], componentsArray)
      ),
    }),
    {}
  )

  return (
    <GridGenerator
      wrapperComponent={grid.wrapperComponent}
      containerProps={grid.containerProps}
      gridItemProps={grid.gridItemProps}
      {...gridGeneratorProps}
    />
  )
}

Fields.propTypes = {
  fieldsArray: PropTypes.array.isRequired,
  grid: PropTypes.object.isRequired,
}

export default Fields