/*eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'

import Button from '../Button'
import Item from './Item'
import {
  stateChangedCallback,
  handleValueChange,
  onAddClick,
  onDeleteClick,
  handleEdit,
  moveUp,
  moveDown,
} from './SelectCreate.logic'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
    minWidth: 120,
  },
})

class SelectCreator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      options: props.values[props.name] || [],
      errorMessage: null,
    }

    this.stateChangedCallback = stateChangedCallback.bind(this)
    this.handleValueChange = handleValueChange.bind(this)
    this.onAddClick = onAddClick.bind(this)
    this.onDeleteClick = onDeleteClick.bind(this)
    this.handleEdit = handleEdit.bind(this)
    this.moveUp = moveUp.bind(this)
    this.moveDown = moveDown.bind(this)
  }

  render() {
    const {
      label,
      formControlProps,
      inputLabelProps,
      sortFunction,
    } = this.props

    const options = (
      sortFunction ?
        this.state.options.sort(sortFunction)
        :
        this.state.options
    )

    return (
      <FormControl
        {...formControlProps}
      >
        <FormLabel
          component={'legend'}
          {...inputLabelProps}
        >
          {label}
        </FormLabel>
        <TextField
          name={'value'}
          label={
            this.state.errorMessage ? this.state.errorMessage : 'Nowa wartość'
          }
          value={this.state.value}
          onChange={this.handleValueChange}
          error={this.state.errorMessage ? true : false}
        />
        <br />
        <Button
          variant={'text'}
          onClick={this.onAddClick}
        >
          Dodaj do listy!
        </Button>
        <List>
          {
            options.map((option, index) => (
              <Item
                key={option.value}
                handleEdit={this.handleEdit}
                moveDown={sortFunction ? null : this.moveDown}
                moveUp={sortFunction ? null : this.moveUp}
                onDeleteClick={this.onDeleteClick}
                option={option}
                optionsLength={options.length}
                index={index}
              />
            ))
          }
        </List>
      </FormControl>
    )
  }
}

SelectCreator.propTypes = {
  sortFunction: PropTypes.func,
  label: PropTypes.string,
  labelShrink: PropTypes.bool,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
}

export default withStyles(styles)(SelectCreator)