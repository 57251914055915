/* eslint-disable max-lines*/
import React from 'react'
import PropTypes from 'prop-types'

import GenericStateHandler from './StateHandler'
import GenericRouteNotFound from '../../router/RouteNotFound'
import GenericList from './List'
import GenericEdit from './Edit'
import GenericCreate from './Create'
import withService from './withService'

import { Route, Switch } from 'react-router-dom'
import { history } from '../../store'
import { withRouter } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

const ViewsGenerator = (props) => {
  const withCurrentService = withService(props.serviceName, props.customQuery)

  const StateHandlerComponent = (
    (props.components && props.components.StateHandler) || GenericStateHandler
  )
  const CurrentStateHandler = (
    withCurrentService(
      StateHandlerComponent
    )
  )

  const ListComponent = (
    (props.components && props.components.List) || GenericList
  )
  const List = (
    withCurrentService(
      serviceProps => (
        <ListComponent
          {...props.list}
          create={props.create}
          mainRoute={props.mainRoute}
          {...serviceProps}
        />
      )
    )
  )

  const CreateComponent = (
    (props.components && props.components.Create) || GenericCreate
  )
  const Create = (
    withCurrentService(
      serviceProps => (
        <CreateComponent
          {...props.create}
          mainRoute={props.mainRoute}
          {...serviceProps}
        />
      )
    )
  )

  const EditComponent = (
    (props.components && props.components.Edit) || GenericEdit
  )
  const Edit = (
    withCurrentService(
      serviceProps => (
        <EditComponent
          {...props.edit}
          mainRoute={props.mainRoute}
          {...serviceProps} />
      )
    )
  )

  return (
    <div>
      <CurrentStateHandler
        {...props}
      />
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            exact
            path={`${props.match.url}/`}
            component={List}
          />
          <Route
            path={`${props.match.url}/list`}
            component={List}
          />
          {
            props.create ?
              <Route
                path={`${props.match.url}/create`}
                component={Create}
              />
              :
              null
          }
          {
            props.edit ?
              <Route
                path={`${props.match.url}/edit/:id`}
                component={Edit}
              />
              :
              null
          }
          <Route
            component={
              (props.components && props.components.RouteNotFound) ||
              GenericRouteNotFound
            }
          />
        </Switch>
      </ConnectedRouter>
    </div>
  )
}

ViewsGenerator.propTypes = {
  customQuery: PropTypes.object,
  stateActions: PropTypes.object,
  stateHandlers: PropTypes.object,
  match: PropTypes.object.isRequired,
  serviceName: PropTypes.string.isRequired,
  list: PropTypes.object.isRequired,
  components: PropTypes.object,
  mainRoute: PropTypes.string.isRequired,
  create: PropTypes.object,
  edit: PropTypes.object,
}

export default withRouter(ViewsGenerator)
