import React from 'react'
import PropTypes from 'prop-types'

import PhotosSelect from './PhotosSelect'

const PhotosSelectFormikWrapper = (props) => {
  const {
    name,
    label,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    formControlProps,
    inputLabelProps,
    renderValue,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    options,
    classes,
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <PhotosSelect
      name={name}
      classes={classes}
      formControlProps={formControlProps}
      inputLabelProps={inputLabelProps}
      label={label}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      touched={touched}
      errors={errors}
      renderValue={renderValue}
      custom={custom}
    />
  )
}

PhotosSelectFormikWrapper.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func, 
  renderValue: PropTypes.func,
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  formControlProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
}

export default PhotosSelectFormikWrapper